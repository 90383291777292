export const get_local_preview = (file) => {
  if (!file) {
    return "";
  }
  return URL.createObjectURL(file);
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};
export const get_readable_storage_size = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(0) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(0) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(0) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

let timer;
export const debounce = (func, timeout = 300) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    func();
  }, timeout);
};

export const getSignAgainstCurrency = (currency, currency_list = []) => {
  if (currency_list.length > 0) {
    const obj = currency_list.find(
      (item) => item.short_form.toUpperCase() === currency.toUpperCase()
    );
    return obj ? obj.symbol : "$";
  } else {
    switch (currency.toUpperCase()) {
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "CNY":
        return "¥";
      case "RUB":
        return "₽";
      case "AUD":
        return "A$";
      default:
        return "$";
    }
  }
};

function addCurrencySymbol(arr1, arr2) {
  // Create a mapping of currency types to symbols from the second array
  const currencyMap = arr2.reduce((map, obj) => {
    map[obj.short_form.toUpperCase()] = obj.symbol;
    return map;
  }, {});

  // Map through the first array and add the corresponding currency symbol
  return arr1.map((obj1) => {
    const currencyTypeUpper = obj1.currency_type.toUpperCase();
    if (currencyMap[currencyTypeUpper]) {
      return {
        ...obj1,
        symbol: currencyMap[currencyTypeUpper],
      };
    }
    return obj1;
  });
}

function addCurrencySymbolToObject(obj, arr2) {
  // Create a mapping of currency types to symbols from the second array
  const currencyMap = arr2.reduce((map, obj) => {
    map[obj.short_form.toUpperCase()] = obj.symbol;
    return map;
  }, {});

  // Check if the currency type in the object matches any in the currency map
  const currencyTypeUpper = obj.currency_type.toUpperCase();
  if (currencyMap[currencyTypeUpper]) {
    obj.currency_type = currencyMap[currencyTypeUpper];
  }

  return obj;
}

export const addSignAgainstCurrency = (plan, plansList, currency_list = []) => {
  if (currency_list.length > 0) {
    plansList.push(addCurrencySymbolToObject(plan, currency_list));
  } else {
    switch (plan.currency_type.toUpperCase()) {
      case "EUR":
        plan.currency_type = "€";
        break;
      case "GBP":
        plan.currency_type = "£";
        break;
      case "CNY":
        plan.currency_type = "¥";
        break;
      case "RUB":
        plan.currency_type = "₽";
        break;

      case "AUD":
        plan.currency_type = "A$";
        break;

      default:
        plan.currency_type = "$";
        break;
    }
    plansList.push(plan);
  }
};
