import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { _sections_list } from "../../DAL/template/template";

import {
  base_uri,
  s3baseUrl,
  s3builderSource,
  asserts_base_url,
  client_side_url,
  box_drawer_option,
  s3builderSourceForFiles,
  tinyMCEKey,
  pexelsImagesKey,
} from "../../config/config";
import {
  _add_domain_against_project,
  _make_unzip_file_of_project,
  _publish_project_on_domain,
  _save_user_design,
  _save_user_design_html,
  _update_user_design_project,
} from "../../DAL/projects/projects";
import { GetSectionCss, GetSectionJS } from "../../utils/sectionData";
import {
  _get_user_design_details_page,
  _list_page_group,
  _update_user_design_page,
} from "../../DAL/projects-pages/projectspages";
import { _making_zip_of_project } from "../../DAL/download/download";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import {
  _dispatch_get_classes_from_dom,
  _dispatch_get_styles_from_dom,
  _dispatch_get_heightwidth_from_dom,
  _dispatch_update_classes_in_dom,
  _dispatch_update_styles_in_dom,
  _dispatch_update_classes_by_element_in_dom,
  _dispatch_get_class_by_element_in_dom,
  _dispatch_update_styles_by_element_in_dom,
  _dispatch_get_styles_by_element_from_dom,
  _dispatch_find_lists_by_id_and_child,
  _dispatch_find_i_frames_by_id,
  _dispatch_get_dataset_by_settingId,
  _dispatch_update_title_by_settingId,
  _dispatch_handle_href_target,
  _dispatch_find_form_by_id,
  _dispatch_find_plan_card_by_id,
  _find_parent_row_of_element,
  _find_section_of_element,
  _find_is_editor_element,
  _dispatch_find_nav_list_and_child,
  _find_element_has_class,
  _dispatch_find_element_by_id_and_child,
} from "./utils";
import { _generate_random_string } from "./utils/Basic-Functions/Basic-Functions";
import { _get_user, _is_dev } from "../../DAL/localStorage/localStorage";
import { height } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import {
  _s3upload_with_name,
  _vissioon_page_thumbnail_update,
} from "../../DAL/s3upload/s3upload";
import { element } from "prop-types";
import {
  InitializeTinyEditor,
  RemoveContentEditable,
  ShowEditor,
  CloseAllEditor,
} from "../../utils/inlineeTinyEditor";
import {
  get_stock_images,
  get_template_images,
  get_user_gallery,
  get_user_pexels_saved_images,
} from "../../DAL/User-Gallery/user_gallery";
import { detail_customer_by_token } from "../../DAL/customer/Customer";
import { _dispatch_find_section_type_by_id } from "./utils/DOM-Basic-Manipulations/DOM-Basic-Manipulations";
import { _send_query_to_ai } from "../../DAL/AIAssistant/ai";
import { minify } from "html-minifier-terser";
import moment from "moment";
import { _public_keys } from "../../DAL/keys/keys";
import {
  google_font_family_classes,
  userTypes,
} from "../../ui_libs/Constants/constant";
import { addSignAgainstCurrency, debounce } from "../../utils/utils";
import { list_all_plan_by_project_id } from "../../DAL/user-created-plans/userCreatedPlans";
import { currency_list_of_vissioon_coach } from "../../DAL/PaymentPlans/paymentPlan";

var myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");

var myInit = {
  method: "GET",
  headers: myHeaders,
};

const BuilderContext = React.createContext();

export const useBuilder = () => useContext(BuilderContext);
let FirstAttemp_Drop = true;
let current_target_element = "";
let current_hovering_over_element = "";

let previous_target_element = "";
let previous_hovering_over_element = "";

export function ContextBuilder({ children }) {
  /* ------------------------------------------------------
  ------------------| Hooks Functions |--------------------
  ------------------------------------------------------- */

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  // Listener Added Map States
  const [clickListenerElementSet, setClickListenerElementSet] = useState(
    new Set()
  );
  const [rightClickListenerElementSet, setRightClickListenerElementSet] =
    useState(new Set());
  const [rowListenerElementSet, setRowListenerElementSet] = useState(new Set());
  const [sliderListenerElementSet, setSliderListenerElementSet] = useState(
    new Set()
  );
  const [navListListenerElementSet, setNavListListenerElementSet] = useState(
    new Set()
  );
  const [listListenerElementSet, setListListenerElementSet] = useState(
    new Set()
  );
  const [imageListenerElementSet, setImageListenerElementSet] = useState(
    new Set()
  );
  const [wbBoxListenerElementSet, setWbBoxListenerElementSet] = useState(
    new Set()
  );
  const [iframeListenerElementSet, setIFrameListenerElementSet] = useState(
    new Set()
  );
  const [formListenerElementSet, setFormListenerElementSet] = useState(
    new Set()
  );
  /* ------------------------------------------------------
  ------------------/ Hooks Functions /--------------------
  ------------------------------------------------------- */

  /* ------------------------------------------------------
  -------------------| Drawers States |--------------------
  ------------------------------------------------------- */

  const [leftMenu, setLeftMenu] = useState("ALL");
  const [leftMenuOpen, setLeftMenuOpen] = useState(true);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);
  const [editorMenuOpen, setEditorMenuOpen] = useState(false);
  const [OpenRowSetting, setOpenRowSetting] = useState(false);
  const [openSliderSettings, setOpenSliderSettings] = useState(false);
  const [targetSliderId, setTargetSliderId] = useState("");
  const [SelectedRow, setSelectedRow] = useState("");
  const [openListBlockDrawer, setOpenListBlockDrawer] = useState(false);
  const [targetSectionId, setTargetSectionId] = useState("");
  const [SelectedRowClasses, setSelectedRowClasses] = useState([]);
  const [rightThemeMenuOpen, setRightThemeMenuOpen] = useState(false);
  const [mobilePreview, setMobilePreview] = useState(false);
  const [tabletPreview, setTabletPreview] = useState(false);
  const [changeInHTML, setChangeInHTML] = useState(false);
  const [previewURL, setPreviewURL] = useState("");
  const [sectionSettingsMenuOpen, setSectionSettingsMenuOpen] = useState(false);
  const [planSettingsMenuOpen, setPlanSettingsMenuOpen] = useState(false);
  const [eventSettingsMenuOpen, setEventSettingsMenuOpen] = useState(false);
  const [FormSettingsMenuOpen, setFormSettingsMenuOpen] = useState(false);
  const [formElementId, setFormElementId] = useState("");
  const requiredFieldsList = [
    "first_name",
    "last_name",
    "email",
    "name",
    "full_name",
  ];
  const [activePaymentPlansList, setActivePaymentPlansList] = useState([]);
  const [currencyListOfVissionUser, setCurrencyListOfVissionUser] = useState(
    []
  );
  const [openIframeSettingMenu, setOpenIframeSettingMenu] = useState(false);
  const [openIframeSettingEditor, setOpenIframeSettingEditor] = useState(null);
  const [selectedIFRAME, setSelectedIFRAME] = useState(null);
  const [openPlanCardSetting, setOpenPlanCardSetting] = useState(false);
  const [selectedNavBarElement, setSelectedNavBarElement] = useState(null);
  const [bookACallEventListing, setBookACallEventListing] = useState([]);
  const navigate = useNavigate();
  /* ------------------------------------------------------
  -------------------/ Drawers States /--------------------
  ------------------------------------------------------- */

  //================= customer detail ================

  const [customerSubscriptionDetail, setCustomerSubscriptionDetail] =
    useState(null);

  // -------------------/ Modal States (start) /--------------------//

  const [headerContentModal, setHeaderContentModal] = useState(false);
  const [aIModalOpen, setAIModalOpen] = useState(false);
  const [aIModalMinimize, setAIModalMinimize] = useState(false);
  const [aiWriterModal, setAiWriterModal] = useState(false);

  // -------------------/ Modal States (end) /--------------------//

  // -------------------/ Dialog States (start) /--------------------//
  const [isHeaderDialogOpen, setIsHeaderDialogOpen] = useState(false);
  // -------------------/ Dialog States (end) /--------------------//

  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSectionsIndex, setSelectedSectionsIndex] = useState(null);
  const [selectedSectionSettingId, setSelectedSectionSettingId] = useState("");
  const [selectedSectionElement, setSelectedSectionElement] = useState(null);

  const [editAbleInputs, setEditAbleInputs] = useState({
    text: "",
    href: "",
    src: "",
    width: "",
    height: "",
  });

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(true);
  const [selectedElement, setSelectedElement] = useState(null);
  const [openReplaceElementMenu, setOpenReplaceElementMenu] = useState(false);
  const [isReplaceableElement, setIsReplaceableElement] = useState(true);
  const [addElementOnTop, setAddElementOnTop] = useState(false);

  // SELECTED ELEMENT ATTRIBUTES STATES
  const [selectedElementAttributes, setSelectedElementAttributes] = useState(
    {}
  );

  const [rootValues, setRootValues] = useState({
    primary_color: "#FFFFFF",
    secondary_color: "#EB144C",
    theme_heading_color: "#222222",
    primary_text_color: "#000000",
    secondary_text_color: "#FFFFFF",
    hover_text_color: "#FFFFFF",
    hover_background_color: "#EB144C",
    font_family: "'Roboto', sans-serif",
    theme_box_shadow: "#ABB8C3",
    // further status
    theme_mode: "light",
    theme_h1_font_size: "2.500rem",
    theme_h2_font_size: "2.000rem",
    theme_h3_font_size: "1.750rem",
    theme_h4_font_size: "1.500rem",
    theme_p_font_size: "1.000rem",
    theme_h1_font_weight: "500",
    theme_h2_font_weight: "500",
    theme_h3_font_weight: "500",
    theme_h4_font_weight: "500",
    theme_p_font_weight: "500",
    theme_line_height: "1.5",
    theme_h1_font_family: "",
    theme_h2_font_family: "",
    theme_h3_font_family: "",
    theme_h4_font_family: "",
  });

  //<<<==========Edit mode========>>>
  const [editMode, setEditMode] = useState(true);
  const [isDev, setIsDev] = useState(false);
  //<<<==========Meta states========>>>
  const [metaStates, setMetaStates] = useState({
    pageTitle: "",
    pageDescription: "",
    keywords: "",
    favicon: "",
    socialTitle: "",
    socialPageUrl: "",
    socialDescription: "",
    socialImageUrl: "",
    robots: "",
    themeColor: "",
  });
  //<<<==========page handling ========>>>
  const [SelectedPage, setSelectedPage] = useState("index");
  const [UseEditingProject, setUseEditingProject] = useState(false);

  const [LastSaveData, setLastSaveData] = useState("");
  const editorRef = useRef(null);

  const [openSelectedBoxDiv, setOpenSelectedBoxDiv] = useState(false);
  const [selectedBoxDiv, setSelectedBoxDiv] = useState("");

  // user gallery
  const [userImagesList, setUserImagesList] = useState([]);
  const [searchImageList, setSearchImageList] = useState([]);
  const [userVideosList, setUserVideosList] = useState([]);
  const [searchVideosList, setSearchVideosList] = useState([]);
  const [templateImagesList, setTemplateImagesList] = useState([]);
  const [searchTemplateImageList, setSearchTemplateImageList] = useState([]);
  const [pexelSavedImageList, setPexelSavedImageList] = useState([]);
  const [searchPexelSavedImageList, setSearchPexelSavedImageList] = useState(
    []
  );
  const [stockImagesList, setStockImagesList] = useState([]);
  const [searchStockImageList, setStockSearchImageList] = useState([]);

  // left menu
  const [leftDrawerType, setLeftDrawerType] = useState("closed");
  const [selectedlistItem, setSelectedlistItem] = useState("");
  const [drawerWidth, setDrawerWidth] = useState(40);
  const [funnelGroup, setFunnelGroup] = useState([]);
  const [funnelDetails, setFunnelDetails] = useState({});

  // Add Section Dialog
  const [addSectionDialog, setAddSectionDialog] = useState(false);

  // image Editor
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  // icon Editor
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const [buttonEditorAnchorEl, setButtonEditorAnchorEl] = useState(null);
  const [textEditorAnchorEl, setTextEditorAnchorEl] = useState(null);
  const [iconEditorPosition, setIconEditorPosition] = useState("");
  // Right Menu
  const [rightMenuAnchorEl, setRightMenuAnchorEl] = useState(null);
  const [navlistButtonPopupAnchorEl, setNavlistButtonPopupAnchorEl] =
    useState(null);

  // Popup Buttons
  const [editButtonPopupAnchorEl, setEditButtonPopupAnchorEl] = useState(null);
  const [iframeButtonPopupAnchorEl, setIframeButtonPopupAnchorEl] =
    useState(null);
  const [listButtonAnchorEl, setListButtonAnchorEl] = useState(null);
  const [layoutSettingButtonAnchorEl, setLayoutSettingButtonAnchorEl] =
    useState(null);
  const [sliderSettingButtonAnchorEl, setSliderSettingButtonAnchorEl] =
    useState(null);
  const [boxSettingButtonAnchorEl, setBoxSettingButtonAnchorEl] =
    useState(null);
  const [editButtonPopupText, setEditButtonPopupText] = useState(false);
  const [openStripeButtonModal, setOpenStripeButtonModal] = useState(false);

  // ============= Public keys ===================

  const [publicKeys, setPublicKeys] = useState({});
  const get_public_key = async (name) => {
    if (publicKeys[name]) {
      console.log("edhar sy card ki key leeny aaya he or yaha sy gyi he");

      return publicKeys[name];
    } else {
      const resp = await _public_keys({
        key: name,
      });
      if (resp.code === 200) {
        setPublicKeys({
          ...publicKeys,
          [name]: resp.data,
        });
        console.log(
          "edhar sy card ki key leeny aaya he or yaha sy gyi he ni API sy"
        );

        return resp.data;
      } else {
        console.log(
          "edhar sy card ki key leeny aaya he or yaha sy gyi he ni config sy"
        );
        if (name === "tinymc_key") {
          return tinyMCEKey;
        }
        if (name === "pexel_key") {
          return pexelsImagesKey;
        }
      }
    }
  };

  // ============= Public keys end ===================

  // -================ customerSubscriptionDetail =================

  const _get_user_subscription = async () => {
    if (sessionStorage.getItem("token")) {
      const resp = await detail_customer_by_token();

      console.log(resp, "setCustomerSubscriptionDetail");

      if (resp.code == 200) {
        const sub = await _set_user_subscription(resp.customer);
        setCustomerSubscriptionDetail(resp.customer);
        return resp;
      } else {
        return false;
      }
    } else {
      return customerSubscriptionDetail;
    }
  };

  const _set_user_subscription = async (data) => {
    setCustomerSubscriptionDetail({ ...data });
    return data;
  };

  useEffect(() => {
    _get_user_subscription();
  }, [useNavigate()]);

  /* ------------------------------------------------------
  *************DOM Manipulation General Functions**********
  ------------------------------------------------------- */
  /* ------------------------------------------------------
  -------------DOM Manipulation General Functions----------
  ------------------------------------------------------- */

  /* ------------------------------------------------------
  *************DOM Manipulation Specific Functions*********
  ------------------------------------------------------- */

  /* ------------------------------------------------------
  *************DOM Manipulation Specific Functions*******
  ------------------------------------------------------- */

  // =================-Gallery Work-===========

  const [userAssertDetails, setUserAssertDetails] = useState("");

  const GetImagesList = async (imagesData = null) => {
    let resp = imagesData;
    if (!resp) {
      resp = await get_user_gallery();
      console.log(resp, "user gallery resp");
      if (resp.code == 200) {
        if (
          resp &&
          resp.customer &&
          resp.customer.gallery &&
          resp.customer.gallery.images &&
          resp.customer.gallery.images.length > 0
        ) {
          let images_list = [...resp.customer.gallery.images].reverse();
          setUserImagesList(images_list);
          setSearchImageList(images_list);
          updateUserAsserts(
            resp.customer.gallery.total_asserts_file_count,
            resp.customer.gallery.total_asserts_file_size
          );
        } else {
          setUserImagesList([]);
          setSearchImageList([]);
          updateUserAsserts(0, 0);
        }
        if (
          resp &&
          resp.customer &&
          resp.customer.gallery &&
          resp.customer.gallery.videos &&
          resp.customer.gallery.videos.length > 0
        ) {
          let videos_list = [...resp.customer.gallery.videos].reverse();
          setUserVideosList(videos_list);
          setSearchVideosList(videos_list);
          updateUserAsserts(
            resp.customer.gallery.total_asserts_file_count,
            resp.customer.gallery.total_asserts_file_size
          );
        } else {
          setUserVideosList([]);
          setSearchVideosList([]);
        }
      }
    } else {
      let images_list = [...resp.images].reverse();
      setUserImagesList(images_list);
      setSearchImageList(images_list);
      updateUserAsserts(
        resp.total_asserts_file_count,
        resp.total_asserts_file_size
      );
    }
    return resp;
  };
  const GetTemplateImagesList = async () => {
    const postData = {
      path: rootValues.template + "/assets/",
    };
    const resp = await get_template_images(postData);
    console.log(resp, "GetTemplateImagesList");
    if (resp.code == 200) {
      if (resp && resp.templates && resp.templates.length > 0) {
        let array = [...resp.templates].reverse();
        let images_list = [];
        array.map((image) => {
          if (image.Key != `${rootValues.template}/assets/index`) {
            images_list.push({
              ...image,
              file_path: image.Key,
              file_name: image.Key.replace(
                `${rootValues.template}/assets/`,
                ""
              ),
            });
          }
        });
        setTemplateImagesList([...images_list]);
        setSearchTemplateImageList([...images_list]);
      } else {
        setTemplateImagesList([]);
        setSearchTemplateImageList([]);
      }
    }
  };
  const GetPexelsImagesList = async () => {
    const resp = await get_user_pexels_saved_images();
    console.log(resp, "GetPexelsImagesList");
    if (resp.code == 200) {
      if (resp && resp.data && resp.data.images.length > 0) {
        let array = [...resp.data.images].reverse();
        setPexelSavedImageList([...array]);
        setSearchPexelSavedImageList([...array]);
      } else {
        setPexelSavedImageList([]);
        setSearchPexelSavedImageList([]);
      }
    }
  };

  const GetStockImagesList = async () => {
    const resp = await get_stock_images();
    if (resp.code == 200) {
      if (resp && resp.stock_image && resp.stock_image.length > 0) {
        let images_list = [...resp.stock_image].reverse();
        setStockImagesList(images_list);
        setStockSearchImageList(images_list);
      } else {
        setStockImagesList([]);
        setStockSearchImageList([]);
      }
    }
  };

  const updateUserAsserts = (file_count, file_size) => {
    let temp = { file_count: file_count, file_size: file_size };
    setUserAssertDetails({ ...temp });
  };

  const getPlanList = async () => {
    let resp = await list_all_plan_by_project_id(rootValues.project);
    if (resp.code == 200) {
      let currencyList = await getCurrencyListOfVissionUser();
      let plansList = [];
      resp.data.map((plan) => {
        addSignAgainstCurrency(plan, plansList, currencyList);
      });
      let list = [];
      plansList.map((plan) => {
        if (
          plan.page_group_id &&
          plan.page_group_id === rootValues.page_group_id
        ) {
          list.push(plan);
        }
        if (!plan.page_group_id && _get_user().type == userTypes.inovate) {
          list.push(plan);
        }
      });
      if (!rootValues.page_group_id) {
        setActivePaymentPlansList(plansList);
      } else if (list.length > 0) {
        setActivePaymentPlansList(list);
      } else {
        if (rootValues.page_group_id !== "") {
          setActivePaymentPlansList([]);
        } else {
          setActivePaymentPlansList(plansList);
        }
      }
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const getCurrencyListOfVissionUser = async () => {
    if (_get_user().type === userTypes.vissioon) {
      const resp = await currency_list_of_vissioon_coach();
      if (resp.code == 200) {
        setCurrencyListOfVissionUser(resp.currency_list);
        return resp.currency_list;
      } else {
        setCurrencyListOfVissionUser([]);
        return [];
      }
    } else {
      return [];
    }
  };

  const Get_Tag_and_Class = (element, name, search) => {
    let tagname = element.tagName;
    if (tagname == name) {
      let classname = element.className ? element.className : null;
      let class_list = element.classList;
      if (classname != null && class_list) {
        // let result = classname.search(new RegExp("\\b" + search + "\\b"));
        let result = class_list.contains(search);
        // if (result >= 0) {
        //   return true;
        // }
        if (result) {
          return true;
        }
      }
    }
    return false;
  };
  const getParentNode = (node) => {
    let element = node;
    while (
      element.parentNode &&
      element.parentNode.children &&
      element.parentNode.children.length == 1
    ) {
      element = element.parentNode;
    }
    return element;
  };
  const CheckElementIsContainBoxClas = (element) => {
    let result = false;
    // while (element.parentNode) {
    //   result = Get_Tag_and_Class(element, "DIV", "wb-box");

    //   if (result) {
    //     let first_child = element.firstChild ? element.firstChild : null;
    //     let myresult = Get_Tag_and_Class(first_child, "DIV", "wp-box-block");
    //     if (!myresult) {
    //       // icon
    //       const icon = document.createElement("span");
    //       icon.classList.add("wp-box-click");
    //       // icon.classList.add("fa-solid");
    //       // icon.classList.add("fa-gear");
    //       icon.innerText = " Box";

    //       // outer div
    //       const outerdiv = document.createElement("div");
    //       // outerdiv.prepend(text);
    //       outerdiv.prepend(icon);
    //       outerdiv.classList.add("wp-box-block");

    //       element.prepend(outerdiv);
    //     }
    //     break;
    //   }

    //   element = element.parentNode;
    // }

    result = Get_Tag_and_Class(element, "DIV", "wb-box");
    if (result) {
      if (wbBoxListenerElementSet.has(element)) {
        return;
      } else {
        setWbBoxListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }
      element.addEventListener("mouseenter", (e) => {
        console.log("mouseenter wb-box");
        setBoxSettingButtonAnchorEl(element);
        // element.style.outline = "1px solid blue";
        const editButton = document.querySelector(
          ".wb-box-setting-setting-button"
        );
        editButton.style.visibility = "visible";
        let elementPos = element.getBoundingClientRect();
        let x = elementPos.left + e.target.clientWidth / 2,
          y =
            elementPos.top + document.getElementsByTagName("HTML")[0].scrollTop;

        editButton.style.left = `${x}px`;
        editButton.style.top = `${y}px`;
      });

      element.addEventListener("mouseleave", () => {
        setBoxSettingButtonAnchorEl(null);
        // element.style.outline = "none";
      });
    }

    // element.addEventListener("mousedown", (e) => {
    //   e.stopImmediatePropagation();
    //   e.stopImmediatePropagation();
    //   let clicked_element = e.target;

    //   let parent = clicked_element.parentNode;

    //   if (Get_Tag_and_Class(clicked_element, "SPAN", "wp-box-click")) {

    //     console.log(parent.parentNode,"dcmdjjcdjcndjncjndjcnjdnjc")
    //     // box logic
    //     setSelectedBoxDiv(parent.parentNode);
    //     setTimeout(() => {
    //       setOpenSelectedBoxDiv(true);
    //     }, 100);
    //     return;
    //   }
    // });
  };

  const BoxSettingClick = (e) => {
    let clicked_element = e;
    setSelectedBoxDiv(clicked_element);
    SaveActionToStack("Box setting");
    setTimeout(() => {
      setOpenSelectedBoxDiv(true);
    }, 100);
    return;
  };

  const CheckElementIsRow = (element) => {
    let rowResult = false;
    // while (element.parentNode) {
    //   result = Get_Tag_and_Class(element, "DIV", "row");

    //   if (result) {
    //     let first_child = element.firstChild ? element.firstChild : null;
    //     let myresult = Get_Tag_and_Class(first_child, "DIV", "wp-row-block");
    //     if (!myresult) {
    //       // icon
    //       const icon = document.createElement("i");
    //       icon.classList.add("wp-row-click");
    //       icon.classList.add("fa-solid");
    //       icon.classList.add("fa-gear");
    //       icon.innerText = " Layout";

    //       // outer div
    //       const outerdiv = document.createElement("div");
    //       // outerdiv.prepend(text);
    //       outerdiv.prepend(icon);
    //       outerdiv.classList.add("wp-row-block");

    //       element.prepend(outerdiv);
    //     }
    //     break;
    //   }

    //   element = element.parentNode;
    // }
    // element.addEventListener("mousedown", (e) => {
    //   e.stopImmediatePropagation();
    //   e.stopImmediatePropagation();
    //   let clicked_element = e.target;

    //   let parent = clicked_element.parentNode;

    //   if (Get_Tag_and_Class(clicked_element, "I", "wp-row-click")) {
    //     setSelectedRow(parent.parentNode);
    //     setSelectedElement(parent.parentNode);
    //     setSelectedRowClasses(parent.parentNode.classList);
    //     setTimeout(() => {
    //       setOpenRowSetting(true);
    //     }, 100);
    //     return;
    //   }
    // });

    rowResult = Get_Tag_and_Class(element, "DIV", "row");
    if (rowResult) {
      if (rowListenerElementSet.has(element)) {
        return;
      } else {
        setRowListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }
      element.addEventListener("mouseenter", (e) => {
        console.log("mouseenter row");
        setLayoutSettingButtonAnchorEl(element);
        // element.style.outline = "1px solid blue";
        const editButton = document.querySelector(
          ".wb-layout-setting-setting-button"
        );
        editButton.style.visibility = "visible";
        let elementPos = element.getBoundingClientRect();
        let x = elementPos.left,
          y =
            elementPos.top + document.getElementsByTagName("HTML")[0].scrollTop;

        editButton.style.left = `${x}px`;
        editButton.style.top = `${y}px`;
      });

      element.addEventListener("mouseleave", () => {
        setLayoutSettingButtonAnchorEl(null);
        element.style.removeProperty("outline");
      });
    }
  };

  const handleOpenLayoutSetting = (element) => {
    setSelectedRow(element);
    setSelectedElement(element);
    setSelectedRowClasses(element.classList);
    setTimeout(() => {
      setOpenRowSetting(true);
    }, 100);
  };

  const CheckElementIsSlider = (element) => {
    let carouselResult = false;
    carouselResult = Get_Tag_and_Class(element, "DIV", "carousel");
    if (!carouselResult) {
      carouselResult = Get_Tag_and_Class(element, "DIV", "owl-carousel");
    }
    if (carouselResult) {
      if (sliderListenerElementSet.has(element)) {
        return;
      } else {
        setSliderListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }
      element.addEventListener("mouseenter", (e) => {
        console.log("mouseenter slider");
        setSliderSettingButtonAnchorEl(element);
        const editButton = document.querySelector(
          ".wb-slider-setting-setting-button"
        );
        editButton.style.visibility = "visible";
        let elementPos = element.getBoundingClientRect();

        let x = elementPos.right - elementPos.width + 24,
          y =
            elementPos.top + document.getElementsByTagName("HTML")[0].scrollTop;
        editButton.style.right = `${x}px`;
        editButton.style.top = `${y}px`;
      });

      element.addEventListener("mouseleave", () => {
        setSliderSettingButtonAnchorEl(null);
      });
    }
  };

  const handleOpenSliderSetting = (element) => {
    setSelectedElement(element);
    setTimeout(() => {
      setOpenSliderSettings(true);
    }, 100);
  };

  const isElementOfEditor = (element) => {
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element.classList && element.classList.contains("wb-editor-block")) {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    return element_found;
  };
  // quick option for list ul and ol
  const checkElementIsList = (element) => {
    if (!element) return true;
    if (element.classList?.contains("wb-disable")) return true;
    let targetElement = element;
    let NavList = false;
    while (targetElement && targetElement.parentNode) {
      if (targetElement.tagName == "SECTION") {
        break;
      } else if (targetElement.tagName == "NAV") {
        NavList = true;
        break;
      }
      targetElement = targetElement.parentNode;
    }

    if (
      (!NavList || !element.classList.contains("navbar-nav")) &&
      (element.tagName == "UL" || element.tagName == "OL")
    ) {
      // let checkListWraperExist = [
      //   ...element.getElementsByClassName("wp-list-block"),
      // ];
      // element.style.position = "relative";
      // if (checkListWraperExist && checkListWraperExist.length > 0) {
      //   handleAddListListner(checkListWraperExist[0]);
      //   return;
      // }
      // let listWraper = document.createElement("div");
      // listWraper.innerHTML = `<i class="wp-list-click fa-solid fa-gear"> List</i>`;
      // listWraper.classList.add("wp-list-block");
      // element.prepend(listWraper);
      // handleAddListListner(listWraper);

      if (listListenerElementSet.has(element)) {
        return;
      } else {
        setListListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }

      element.addEventListener("mouseenter", (e) => {
        console.log("mouseenter list");
        setListButtonAnchorEl(element);
        // element.style.outline = "1px solid blue";
        const editButton = document.querySelector(".wb-list-setting-button");
        editButton.style.visibility = "visible";
        let elementPos = element.getBoundingClientRect();
        let x = elementPos.left,
          y =
            elementPos.top + document.getElementsByTagName("HTML")[0].scrollTop;
        editButton.style.left = `${x}px`;
        editButton.style.top = `${y}px`;
      });

      element.addEventListener("mouseleave", () => {
        setListButtonAnchorEl(null);
        // element.style.outline = "none";
      });
    }
  };

  // const handleAddListListner = (element) => {
  //   element.addEventListener("click", (e) => {
  //     setSelectedElement(element.parentNode);
  //     setOpenListBlockDrawer(true);
  //   });
  // };

  const handleAddListListner = (element) => {
    setSelectedElement(element);
    setElementsCopyToSessionStorage(element);
    setOpenListBlockDrawer(true);
    SaveActionToStack("list setting");
  };

  const checkElementIsNavList = (element) => {
    if (!element) return true;
    let targetElement = element;
    while (targetElement && targetElement.parentNode) {
      if (targetElement.tagName == "SECTION") {
        return;
      } else if (targetElement.tagName == "NAV") {
        break;
      }
      targetElement = targetElement.parentNode;
    }
    if (
      (element.tagName == "UL" || element.tagName == "OL") &&
      element.classList.contains("navbar-nav")
    ) {
      // let checkListWraperExist = [
      //   ...element.getElementsByClassName("wp-list-block"),
      // ];
      // element.style.position = "relative";
      // if (checkListWraperExist && checkListWraperExist.length > 0) {
      //   handleAddNavListListner(checkListWraperExist[0], targetElement);
      //   return;
      // }
      // let listWraper = document.createElement("div");
      // listWraper.innerHTML = `<i class="wp-list-click fa-solid fa-gear"> Nav List</i>`;
      // listWraper.classList.add("wp-list-block");
      // element.prepend(listWraper);
      // handleAddNavListListner(listWraper, targetElement);
      if (navListListenerElementSet.has(element)) {
        return;
      } else {
        setNavListListenerElementSet((prevSet) =>
          new Set(prevSet).add(element)
        );
      }
      element.addEventListener("mouseenter", () => Add_NavList_Button(element));
      // element.addEventListener("mouseenter", (e) => {
      //   setNavlistButtonPopupAnchorEl(element);

      //   element.style.outline = '1px solid blue'

      //   const editButton = document.querySelector(".wb-navlist-button");
      //   editButton.style.visibility = "visible";
      //   let elementPos = element.getBoundingClientRect();
      //   let x = elementPos.left,
      //     y =
      //       elementPos.top + document.getElementsByTagName("HTML")[0].scrollTop;

      //   editButton.style.left = `${x}px`;
      //   editButton.style.top = `${y}px`;
      // });

      element.addEventListener("mouseleave", () => {
        setNavlistButtonPopupAnchorEl(null);
        // element.style.outline = "none";
      });
    }
  };

  const Add_NavList_Button = (element) => {
    console.log("mouseenter navList");
    setNavlistButtonPopupAnchorEl(element);
    // element.style.outline = "1px solid blue";

    const editButton = document.querySelector(".wb-navlist-button");
    editButton.style.visibility = "visible";
    let elementPos = element.getBoundingClientRect();
    let x = elementPos.left,
      y = elementPos.top + document.getElementsByTagName("HTML")[0].scrollTop;

    editButton.style.left = `${x}px`;
    editButton.style.top = `${y}px`;
  };
  // const handleAddNavListListner = (element, targetElement) => {
  //   element.addEventListener("click", (e) => {
  //     setSelectedNavBarElement(targetElement);
  //     setSelectedElement(element.parentNode);
  //     setOpenListBlockDrawer(true);
  //   });
  // };
  const handleAddNavListListner = (navElement, element) => {
    setElementsCopyToSessionStorage(navElement);
    setSelectedNavBarElement(navElement);
    setSelectedElement(element);
    let selectedSection = _find_section_of_element(element);
    setSelectedSectionSettingId(selectedSection.getAttribute("id"));
    setOpenListBlockDrawer(true);
    SaveActionToStack("navlist");
  };

  // quick option for form
  const checkElementIsForm = (element) => {
    if (element.tagName == "FORM") {
      if (formListenerElementSet.has(element)) {
        return;
      } else {
        setFormListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }
      let checkListWraperExist = [
        ...element.getElementsByClassName("wp-form-block"),
      ];
      element.style.position = "relative";
      if (checkListWraperExist && checkListWraperExist.length > 0) {
        handleAddFormListner(checkListWraperExist[0]);
        return;
      }
      let listWraper = document.createElement("div");
      listWraper.innerHTML = `<i class="wp-form-click fa-solid fa-gear"> Form</i>`;
      listWraper.classList.add("wp-form-block");
      element.prepend(listWraper);
      handleAddFormListner(listWraper);
    }
  };
  const handleAddFormListner = (element) => {
    element.addEventListener("click", (e) => {
      let target = element;
      while (target && target.parentNode) {
        if (target.tagName == "SECTION") {
          let sectionID = target.parentNode.parentNode.getAttribute("id");
          if (sectionID) {
            setSelectedSectionSettingId(sectionID);
            setSelectedElement(element.parentNode);
            if(element.parentNode.getAttribute("id")){
              setFormElementId(element.parentNode.getAttribute("id"));
            }else{
              setFormElementId("");
            }
            setFormSettingsMenuOpen(true);
          }
        }
        target = target.parentNode;
      }
    });
  };
  // quick option for image
  const checkElementIsImage = (element) => {
    if (element.tagName == "IMG") {
      if (imageListenerElementSet.has(element)) {
        return;
      } else {
        setImageListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }
      element.addEventListener("mouseenter", (e) => {
        console.log("mouseenter img");
        // setSelectedElement(e.target);
        if (e.target.width > "150") {
          setEditButtonPopupText(true);
        } else {
          setEditButtonPopupText(false);
        }
        if (e.target.width > "49" && e.target.height > "49") {
          setEditButtonPopupAnchorEl(e);
          const editButton = document.querySelector(".edit-button-wrapper");
          editButton.style.visibility = "visible";
          let elementPos = e.target.getBoundingClientRect();

          let x = elementPos.left + e.target.width / 2,
            y =
              elementPos.top +
              document.getElementsByTagName("HTML")[0].scrollTop +
              e.target.height / 2;

          editButton.style.left = `${x}px`;
          editButton.style.top = `${y}px`;
        }
      });

      element.addEventListener("mouseleave", () => {
        setEditButtonPopupAnchorEl(null);
      });
    }
  };

  // quick option for iframe
  const checkElementIsIFRAME = (element) => {
    let modal = _find_element_has_class(element, "modal");
    if (modal && !modal.getAttribute("data-bs-focus")) {
      modal && modal.setAttribute("data-bs-focus", "false");
      console.log(modal, "mehorahbebebj");
    }

    // if (element.tagName == "IFRAME") {
    //   let targetNode = element.parentNode;
    //   let checkListWraperExist = [
    //     ...targetNode.getElementsByClassName("wp-iframe-block"),
    //   ];
    //   targetNode.style.position = "relative";
    //   if (checkListWraperExist && checkListWraperExist.length > 0) {
    //     handleAddIFRAMEListner(checkListWraperExist[0]);

    //     return;
    //   }
    //   let listWraper = document.createElement("div");
    //   listWraper.innerHTML = `<i class="wp-iframe-click fa-solid fa-gear"> IFRAME</i>`;
    //   listWraper.classList.add("wp-iframe-block");
    //   targetNode.prepend(listWraper);
    //   handleAddIFRAMEListner(listWraper);
    // }

    if (element.tagName == "IFRAME") {
      if (iframeListenerElementSet.has(element)) {
        return;
      } else {
        setIFrameListenerElementSet((prevSet) => new Set(prevSet).add(element));
      }
      element.addEventListener("mouseenter", (e) => {
        // setSelectedElement(e.target);
        console.log("mouseenter iframe");
        setIframeButtonPopupAnchorEl(e);
        const editButton = document.querySelector(
          ".iframe-edit-button-wrapper"
        );
        editButton.style.visibility = "visible";
        let elementPos = e.target.getBoundingClientRect();

        let x = elementPos.left + element.parentNode.offsetWidth / 2,
          y =
            elementPos.top +
            document.getElementsByTagName("HTML")[0].scrollTop +
            element.parentNode.offsetHeight / 2;

        editButton.style.left = `${x}px`;
        editButton.style.top = `${y}px`;
      });

      element.addEventListener("mouseleave", () => {
        setIframeButtonPopupAnchorEl(null);
      });
    }
  };
  const handleAddIFRAMEListner = (e, element) => {
    console.log("Iframe Listner");
    // element.addEventListener("click", (e) => {
    setSelectedIFRAME(element.parentNode);
    setElementsCopyToSessionStorage(element.parentNode);
    // setOpenIframeSettingMenu(true);
    handleEditorPosition(e);
    setOpenIframeSettingEditor(e);
    // });
  };

  const handleSectionBackgroundVideoRun = (targetSection = null) => {
    let videoContainers = targetSection
      ? targetSection.querySelectorAll(".wb-section-background-video-container")
      : document.querySelectorAll(".wb-section-background-video-container");
    videoContainers.forEach(function (videoContainer) {
      let videoId = videoContainer.getAttribute("data-video_id");
      let videoType = videoContainer.getAttribute("data-video_type");
      let addOverlay = videoContainer.getAttribute("data-video_overlay");
      let overLayColor = videoContainer.getAttribute(
        "data-video_overlay_color"
      );
      if (videoId) {
        if (videoType === "youtube") {
          videoContainer.innerHTML = "";
          let innerVideoDiv = document.createElement("div");
          innerVideoDiv.id = "wb-background-video-player";
          videoContainer.appendChild(innerVideoDiv);
          if (
            !document.querySelector(
              'script[src="https://www.youtube.com/iframe_api"]'
            )
          ) {
            let tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            let firstScriptTag = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
          }
          if (window.YT && window.YT.Player) {
            createYouTubePlayer(videoContainer, videoId);
          } else {
            window.onYouTubeIframeAPIReady = function () {
              createYouTubePlayer(videoContainer, videoId);
            };
          }
        } else if (videoType === "vimeo") {
          videoContainer.innerHTML = "";
          let innerVideoDiv = document.createElement("div");
          innerVideoDiv.id = "wb-background-video-player";
          videoContainer.appendChild(innerVideoDiv);

          createVimeoPlayer(innerVideoDiv, videoId);
        } else {
          const videoElement = videoContainer.querySelector(
            `#uploadedVideo-${videoId}`
          );
          videoElement.autoplay = true;
          videoElement.muted = true;
          videoElement.play();
        }
        if (
          addOverlay == "true" &&
          !videoContainer.querySelector(`.video-overlay-div`)
        ) {
          let overLayDiv = document.createElement("div");
          overLayDiv.className = "video-overlay-div";
          overLayDiv.style.background = overLayColor;
          videoContainer.appendChild(overLayDiv);
        }
      }
    });
  };

  function createYouTubePlayer(container, videoId) {
    container.player = new YT.Player(
      container.querySelector("#wb-background-video-player"),
      {
        videoId: videoId,
        playerVars: {
          autoplay: 1,
          loop: 1,
          controls: 0,
          modestbranding: 1, // Minimize YouTube branding
          mute: 1,
          playlist: videoId,
          rel: 0, // Disable related videos at the end
          start: 0, // Start from the beginning
          showinfo: 0, // Hide video info
          iv_load_policy: 3, // Disable annotations
        },
        events: {
          onReady: onYoutubePlayerReady,
          onStateChange: onYoutubePlayerStateChange,
          onError: onYoutubePlayerError,
        },
      }
    );
  }

  function createVimeoPlayer(container, videoId) {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "vimeoPlayer");
    iframe.setAttribute(
      "src",
      "https://player.vimeo.com/video/" +
        videoId.replace("/", "?h=") +
        "&background=1&autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0"
    );
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allow", "autoplay; fullscreen");
    iframe.setAttribute("allowfullscreen", "");
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.position = "absolute";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.objectFit = "cover";
    console.log(iframe, "createVimeoPlayercreateVimeoPlayer");
    container.appendChild(iframe);

    if (
      !document.querySelector(
        'script[src="https://player.vimeo.com/api/player.js"]'
      )
    ) {
      let tag = document.createElement("script");
      tag.src = "https://player.vimeo.com/api/player.js";
      let firstScriptTag = document.getElementsByTagName("script")[0];
      tag.onload = () => onVimeoPlayerReady(iframe);
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else if (window.Vimeo && window.Vimeo.Player) {
      onVimeoPlayerReady(iframe);
    } else {
      window.onVimeoAPIReady = function () {
        onVimeoPlayerReady(iframe);
      };
    }
  }

  function onVimeoPlayerReady(iframe) {
    var player = new Vimeo.Player(iframe);

    player.on("play", function () {
      console.log("Vimeo video is playing");
      player.setVolume(0);
      player.play();
      adjustVideoSize(player.element);
      window.addEventListener("resize", function () {
        debounce(() => adjustVideoSize(player.element), 500);
      });
    });

    player.on("loaded", function () {
      player.setVolume(0);
      console.log("Vimeo video is loaded");
    });

    player.on("ended", function () {
      player.setCurrentTime(0).then(function () {
        player.play();
      });
    });

    player.on("error", function (error) {
      console.error("Vimeo error:", error);
    });
  }

  function onYoutubePlayerReady(event) {
    event.target.mute();
    event.target.playVideo();
    adjustVideoSize(event.target.g);
    window.addEventListener("resize", function () {
      debounce(() => adjustVideoSize(event.target.g), 500);
    });
  }

  function onYoutubePlayerStateChange(event) {
    if (event.data === YT.PlayerState.ENDED) {
      event.target.playVideo();
    }
  }

  function onYoutubePlayerError(event) {
    console.log("Error occurred: ", event.data);
  }

  function adjustVideoSize(container) {
    if (!container?.parentNode) {
      return;
    }
    console.log(container, "adjustVideoSize is called");
    let videoContainer = container;
    let containerWidth = videoContainer.parentNode.offsetWidth;
    let containerHeight = videoContainer.parentNode.offsetHeight;
    let aspectRatio = 16 / 9;
    let videoWidth = containerWidth;
    let videoHeight = containerWidth / aspectRatio;
    if (videoHeight < containerHeight) {
      videoHeight = containerHeight;
      videoWidth = containerHeight * aspectRatio;
    }
    videoContainer.style.width = videoWidth + "px";
    videoContainer.style.height = videoHeight + "px";
    videoContainer.style.top = "50%";
    videoContainer.style.left = "50%";
    videoContainer.style.transform = "translate(-50%, -50%)";
  }

  const checkElementIsPaymentCard = (element) => {
    const response = Get_Tag_and_Class(element, "DIV", "wb-plan-card");
    if (response) {
      let checkListWraperExist = [
        ...element.getElementsByClassName("wp-plan-card-block"),
      ];
      element.style.position = "relative";
      if (checkListWraperExist && checkListWraperExist.length > 0) {
        handleAddPlanCardListner(checkListWraperExist[0]);
        return;
      }
      let listWraper = document.createElement("div");
      listWraper.innerHTML = `<i class="wp-plan-card-click fa-solid fa-gear"> Payment Card</i>`;
      listWraper.classList.add("wp-plan-card-block");
      element.prepend(listWraper);
      handleAddPlanCardListner(listWraper);
    }
  };
  const handleAddPlanCardListner = (element) => {
    element.addEventListener("click", (e) => {
      setSelectedElement(element.parentNode);
      setOpenPlanCardSetting(true);
    });
  };

  // reinitialine quick option
  const addQuickOptionOnGivenTag = (tagName, checkFunction, targetElement) => {
    let previewDiv = null;
    if (targetElement !== null) {
      previewDiv = targetElement;
    } else {
      previewDiv = document.querySelector("#preview-div");
    }
    let listElements = [];
    if (previewDiv.tagName === tagName) {
      checkFunction(previewDiv);
      return;
    } else {
      listElements = [...previewDiv?.getElementsByTagName(tagName)];
    }
    if (listElements.length > 0) {
      listElements.map((item) => {
        checkFunction(item);
      });
    }
  };
  const addQuickOptionOnAllTags = (targetElement) => {
    addQuickOptionOnGivenTag("UL", checkElementIsNavList, targetElement);
    addQuickOptionOnGivenTag("OL", checkElementIsNavList, targetElement);
    addQuickOptionOnGivenTag("OL", checkElementIsList, targetElement);
    addQuickOptionOnGivenTag("UL", checkElementIsList, targetElement);
    addQuickOptionOnGivenTag("FORM", checkElementIsForm, targetElement);
    addQuickOptionOnGivenTag("IMG", checkElementIsImage, targetElement);
    addQuickOptionOnGivenTag("IFRAME", checkElementIsIFRAME, targetElement);
  };
  const targetElementForAddListener = (element, section) => {
    if (!element) {
      console.error("Invalid Element");
      return;
    }
    CheckElementIsRow(element);
    CheckElementIsSlider(element);
    // checkElementIsNavList(element);
    // checkElementIsList(element);
    // checkElementIsForm(element);
    // checkElementIsImage(element);
    // checkElementIsIFRAME(element);
    checkElementIsPaymentCard(element);
    (getIsDevState() || box_drawer_option) &&
      CheckElementIsContainBoxClas(element);

    if (element && element.classList) {
      if (element.classList.contains("wp-row-block")) {
        // element.remove();
        return;
      } else if (element.classList.contains("wp-box-block")) {
        // element.remove();
        return;
      } else if (element.classList.contains("wp-list-block")) {
        handleAddListListner(element);
        return;
      } else if (element.classList.contains("wp-form-block")) {
        handleAddFormListner(element);
        return;
      } else if (element.classList.contains("wp-iframe-block")) {
        handleAddIFRAMEListner(element);
        return;
      } else if (element.classList.contains("wp-plan-card-block")) {
        handleAddPlanCardListner(element);
        return;
      }
    }
    if (element.children.length > 0) {
      for (let index = 0; index < element.children.length; index++) {
        const targetNode = element.children[index];
        if (targetNode.tagName == "SELECT") {
          addListener(targetNode, section);
        }
        if (targetNode.tagName === "A") {
          console.log(targetNode.tagName, "Target Node");
          // addListener(targetNode)
          targetNode.addEventListener("click", (e) => e.preventDefault());

          // element.addEventListener("click", (e) => {
          //   e.preventDefault();

          //   // console.log(this, "THIS");
          //   // if (e.target === this) {
          //   //   alert("target");
          //   // } else {
          //   //   alert("not");
          //   // }
          //   return false;
          // });
        }

        let is_editor = checkIsEditorElement(targetNode);
        let is_disable = checkIsDisableElement(targetNode);

        // making editor div for editor
        if (
          targetNode.classList &&
          targetNode.classList.contains("wb-stripe-buy-button")
        ) {
          targetNode.children[0].style.pointerEvents = "none";
          addListener(targetNode, section);
          continue;
        }
        if (is_editor) {
          addListener(targetNode, section);
          updateLinks(targetNode, section);
        } else {
          if (targetNode.children.length === 0) {
            // if element has wb-disable class
            // disable events against that element
            if (is_disable) {
              updateLinks(targetNode, section);
            } else {
              addListener(targetNode, section);
              updateLinks(targetNode, section);
            }
          } else {
            targetElementForAddListener(targetNode, section);
          }
        }
      }
    } else {
      alert("targetElementForAddListener else case invoked...");
    }
  };

  const targetElementForRightClickListener = (element) => {
    if (!element) {
      console.error("Invalid Element");
      return;
    }

    const contextMenu = document.querySelector(".right-menu-wrapper");
    if (element) {
      if (rightClickListenerElementSet.has(element)) {
        return;
      } else {
        setRightClickListenerElementSet((prevSet) =>
          new Set(prevSet).add(element)
        );
      }
      element.addEventListener("contextmenu", (e) => {
        console.log(e.target, "heyhyehyehyehyehyeheyeey");
        e.preventDefault();
        let checkElement = e.target;
        if (checkElement && checkElement.classList) {
          if (checkElement.classList.contains("wp-row-click")) {
            return;
          } else if (checkElement.classList.contains("wp-box-click")) {
            return;
          } else if (checkElement.classList.contains("wp-list-click")) {
            return;
          } else if (checkElement.classList.contains("wp-form-click")) {
            return;
          } else if (checkElement.classList.contains("wp-iframe-click")) {
            return;
          } else if (checkElement.classList.contains("wp-plan-card-click")) {
            return;
          } else if (checkElement.classList.contains("wp-row-block")) {
            return;
          } else if (checkElement.classList.contains("wp-box-block")) {
            return;
          } else if (checkElement.classList.contains("wp-list-block")) {
            return;
          } else if (checkElement.classList.contains("wp-form-block")) {
            return;
          } else if (checkElement.classList.contains("wp-iframe-block")) {
            return;
          } else if (checkElement.classList.contains("wp-plan-card-block")) {
            return;
          }
        }

        contextMenu.style.visibility = "visible";
        let elementPos = e.target.getBoundingClientRect();
        if (e.clientY > (elementPos.bottom + elementPos.top) / 2) {
          setAddElementOnTop(false);
        } else {
          setAddElementOnTop(true);
        }

        let x = e.pageX,
          y = e.pageY;

        let winWidth = window.innerWidth;
        let cmWidth = contextMenu.offsetWidth + 30;
        x = x > winWidth - cmWidth ? winWidth - cmWidth : x;

        contextMenu.style.left = `${x}px`;
        contextMenu.style.top = `${y}px`;
        setSelectedElement(e.target);
        setRightMenuAnchorEl(e);

        return false;
      });
    }
  };

  // <---------------Do undo start----------------->

  const [HistoyStack, setHistoyStack] = useState([]);
  const [RedoStack, setRedoStack] = useState([]);
  const [UndoRootValues, setUndoRootValues] = useState([]);
  const [RedoRootValues, setRedoRootValues] = useState([]);

  useEffect(() => {
    setHistoyStack([]);
    setRedoStack([]);
    setSelectedSections([]);
    setUndoRootValues([]);
    setRedoRootValues([]);
  }, [SelectedPage]);

  const handleGetHtmlBeforeAction = (updatedList) => {
    const updatedHtml = updatedList.map((sec) => {
      const updated_html = findElementById(sec.section_id).outerHTML;
      return {
        ...sec,
        section_html: updated_html,
      };
    });
    return [...updatedHtml];
  };
  const handleRollBackLastAction = (updatedList) => {
    const updatedHtml = updatedList.map((sec) => {
      const updated_html = sec.section_html;
      return {
        ...sec,
        section_html: updated_html,
      };
    });
    // console.log(updatedHtml, "updatedHtml");
    setSelectedSections(updatedHtml);
    return updatedHtml;
  };
  const SaveActionToStack = async (str = "unknown") => {
    console.log("SaveActionToStack Called From: ", str);
    setRedoStack([]);
    setRedoRootValues([]);
    // let x = await handleUpdateStateFromDOM(selectedSections)
    let HTMLBeforAction = handleGetHtmlBeforeAction(selectedSections);
    if (HTMLBeforAction.length == 0) {
      return;
    }
    let Current_Root_Values = { ...rootValues };
    UndoRootValues.splice(0, 0, Current_Root_Values);
    HistoyStack.splice(0, 0, HTMLBeforAction);

    if (HistoyStack.length > 15) {
      HistoyStack.splice(15, 1);
      UndoRootValues.splice(15, 1);
    }

    setUndoRootValues([...UndoRootValues]);
    setHistoyStack([...HistoyStack]);

    return true;
  };
  const checkAndRemoveActionFromStack = async (_Element) => {
    // let newCode = await minify(_Element.outerHTML, {
    //   collapseWhitespace: true,
    //   removeComments: true,
    //   minifyJS: true,
    //   minifyCSS: true,
    // });
    // let oldCode = await minify(sessionStorage.getItem("copySelectedElement"), {
    //   collapseWhitespace: true,
    //   removeComments: true,
    //   minifyJS: true,
    //   minifyCSS: true,
    // });
    // console.log(newCode, oldCode,HistoyStack, "SaveActionToStack to removed");
    // if (newCode == oldCode) {

    // let stringg =  compareStrings(_Element.outerHTML, sessionStorage.getItem("copySelectedElement"))
    // let div = document.createElement("div");
    // div.innerHTML = sessionStorage.getItem("copySelectedElement");
    // console.log(stringg,"SaveActionToStack checkAndRemoveActionFromStack called");
    // console.log(div,"SaveActionToStack checkAndRemoveActionFromStack called" ,_Element);
    console.log("SaveActionToStack checkAndRemoveActionFromStack called");
    if (_Element.outerHTML == sessionStorage.getItem("copySelectedElement")) {
      if (HistoyStack.length > 0) {
        HistoyStack.splice(0, 1);
        UndoRootValues.splice(0, 1);
        setUndoRootValues([...UndoRootValues]);
        setHistoyStack([...HistoyStack]);
        console.log("SaveActionToStack removed action from stack");
      }
    }
    sessionStorage.removeItem("copySelectedElement");
  };

  function compareStrings(str1, str2) {
    // Find the minimum length of the two strings
    let minLen = Math.min(str1.length, str2.length);
    // Iterate through the characters and find the first position of difference
    let diffPos = minLen;
    for (let i = 0; i < minLen; i++) {
      if (str1[i] !== str2[i]) {
        diffPos = i;
        break;
      }
    }
    // Return the substring where the strings differ
    return [str1.substring(0, diffPos), str2.substring(0, diffPos)];
  }

  const checkHTMLExistorNotinStack = () => {
    let HTMLBeforAction = handleGetHtmlBeforeAction(selectedSections);
    if (HistoyStack.length == 0) {
      return false;
    }
    if (HistoyStack[0].length !== HTMLBeforAction.length) {
      return false;
    }
    // Compare the HTML code of each object
    for (let i = 0; i < HistoyStack[0].length; i++) {
      if (HistoyStack[0][i].section_html !== HTMLBeforAction[i].section_html) {
        return false; // code is different
      }
    }
    return true; // code exist;
  };

  const checkRootValuesAndRemoveFromStack = () => {
    let copyRootValues = sessionStorage.getItem("copyRootValues");
    copyRootValues = JSON.parse(copyRootValues);
    if (copyRootValues) {
      let rootValuesComparisonList = [
        "font_family",
        "primary_color",
        "secondary_color",
        "theme_heading_color",
        "primary_text_color",
        "secondary_text_color",
        "hover_text_color",
        "hover_background_color",
        "theme_box_shadow",
        "input_border_color",
        "input_placeholder_color",
        "input_text_color",
        "input_background_color",
        "menu_color",
        "menu_hover_color",

        "theme_h1_font_size",
        "theme_h2_font_size",
        "theme_h3_font_size",
        "theme_h4_font_size",
        "theme_p_font_size",
        "theme_line_height",
        "theme_h1_font_weight",
        "theme_h2_font_weight",
        "theme_h3_font_weight",
        "theme_h4_font_weight",
        "theme_p_font_weight",
        "theme_h1_font_family",
        "theme_h2_font_family",
        "theme_h3_font_family",
        "theme_h4_font_family",
        "mobile_font_size",
      ];

      if (
        comparePropertiesOfObject(
          copyRootValues,
          rootValues,
          rootValuesComparisonList
        )
      ) {
        HistoyStack.splice(0, 1);
        UndoRootValues.splice(0, 1);
        setUndoRootValues([...UndoRootValues]);
        setHistoyStack([...HistoyStack]);
        console.log("SaveActionToStack removed action from stack");
      }
      sessionStorage.removeItem("copyRootValues");
    }
  };

  function comparePropertiesOfObject(obj1, obj2, propertyList) {
    for (let property of propertyList) {
      if (obj1[property] !== obj2[property]) {
        return false;
      }
    }
    return true;
  }

  const UpdateDOMHTML = (SectionList) => {
    if (SectionList.length > 0) {
      SectionList.map((sec, index) => {
        const section = document.getElementById(sec.section_id);

        if (section) {
          section.outerHTML = sec.section_html;
        }
      });
    }
  };
  const UndoAction = async () => {
    if (HistoyStack.length > 0) {
      RedoRootValues.splice(0, 0, { ...rootValues });
      let Last_Change_Root_Values = UndoRootValues.splice(0, 1);
      setRootValues({ ...Last_Change_Root_Values[0] });
      setUndoRootValues([...UndoRootValues]);
      setRedoRootValues([...RedoRootValues]);

      let CurrentHTML = handleGetHtmlBeforeAction(selectedSections);

      let HTMLBeforeLastAction = [...HistoyStack.splice(0, 1)];
      // if (RedoStack.length == 0) {
      RedoStack.splice(0, 0, CurrentHTML);
      // } else {
      // RedoStack.splice(0, 0, HTMLBeforeLastAction[0])
      // }
      setHistoyStack([...HistoyStack]);

      setRedoStack([...RedoStack]);

      let HTML_List = await handleRollBackLastAction(HTMLBeforeLastAction[0]);
      if (HTML_List) {
        UpdateDOMHTML(HTMLBeforeLastAction[0]);
      }

      // ReRenderHtml();
    }
  };
  const RedoAction = async () => {
    if (RedoStack.length > 0) {
      let Last_Change_Root_Values = RedoRootValues.splice(0, 1);
      UndoRootValues.splice(0, 0, { ...rootValues });
      setRootValues({ ...Last_Change_Root_Values[0] });
      setUndoRootValues([...UndoRootValues]);
      setRedoRootValues([...RedoRootValues]);

      let HTMLBeforeLastAction = [...RedoStack.splice(0, 1)];
      setRedoStack([...RedoStack]);
      let CurrentHTML = handleGetHtmlBeforeAction(selectedSections);

      HistoyStack.splice(0, 0, CurrentHTML);
      setHistoyStack([...HistoyStack]);
      let HTML_List = await handleRollBackLastAction(HTMLBeforeLastAction[0]);
      if (HTML_List) {
        UpdateDOMHTML(HTMLBeforeLastAction[0]);
      }
      // ReRenderHtml();
    }
  };

  // <---------------Do undo end----------------->

  // <---------------Drag And Drop Start----------------->

  // drag and drop listners start
  const handleDragStart = (e) => {
    CloseAllEditor();
    FirstAttemp_Drop = true;
    current_target_element = "";
    current_hovering_over_element = "";

    previous_target_element = "";
    previous_hovering_over_element = "";

    RemoveRowBlock(document);
    let data = [...document.getElementsByClassName("Drag-Element")];
    for (let a = 0; a < data.length; a++) {
      data[a].classList.remove("Drag-Element");
    }
    e.currentTarget.firstChild.classList.add("Drag-Element");
  };
  const handleAddDragDropListener = (element) => {
    if (!element) {
      return;
    }
    element.addEventListener("dragover", (e) => {
      if (e.target.tagName === "IMG") {
        CheckIncomingElementIsIMG(e);
      }
      if (CheckIncomingElementIsReplaceable(e)) {
        e.preventDefault();
        return;
      }
      let Drag_Element = GetDragElement();
      if (!Drag_Element) return;
      if (e.target.tagName == "SECTION") {
        return;
      } else if (
        e.target.parentNode &&
        e.target.parentNode.tagName == "SECTION"
      ) {
        return;
      }
      if (
        current_target_element &&
        current_hovering_over_element &&
        current_target_element.outerHTML &&
        current_target_element.outerHTML.includes(Drag_Element.outerHTML) &&
        current_target_element.outerHTML.includes(e.target.outerHTML)
      ) {
        SwapElementPositionAccordingTOMouse(e, Drag_Element);
      } else {
        // enqueueSnackbar("DragOver Else Case Invoke", { variant: "warning" });
        // console.log("DragOver Else Case Invoke",current_target_element,current_hovering_over_element);
      }
      e.preventDefault();

      // e.preventDefault();
    });

    element.addEventListener("dragenter", (e) => {
      let Drag_Element = GetDragElement();
      if (CheckIncomingElementIsReplaceable(e)) {
        e.preventDefault();
        return;
      }
      if (!Drag_Element) return;

      // check hover element is drag element
      if (handleEnterDragID(e)) {
        e.preventDefault();
        return;
      }

      // remove element if enter in new element
      RemoveOldEleemntIfEnterInNewElement();

      // check element parent is editor

      if (handleEnterEditor(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      // check element parent is form
      else if (handleEnterFORM(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      // check element is IMG
      else if (handleEnterIMG(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }

      // check element is BUTTON or Anchor
      else if (handleEnterBUTTON_or_ANCHOR(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }

      // check element is I
      else if (handleEnterI(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      // check element is Row
      else if (handleEnterROW(e)) {
        e.preventDefault();
        handleAddElement(e);
        return;
      }
      e.preventDefault();
    });

    element.addEventListener("drop", (e) => {
      if (e.target.tagName === "IMG") {
        let Element = GetUserGalleryIMG();
        if (Element) {
          if (FirstAttemp_Drop) {
            FirstAttemp_Drop = false;
            SaveActionToStack("image drop listener");
          }
        }
      }
      let Drag_Element = GetDragElement();
      if (!Drag_Element) return;
      let iframe_code = `
      <iframe width="560" height="315" src="https://www.youtube.com/embed/inpok4MKVLM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
      `;
      let map_code = `<iframe src="https://www.google.com/maps/embed?pb=1m18!1m12!1m3!1d3431.959416035186!2d73.09375081460895!3d30.663271296029095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922b7d4a0280655%3A0x8569edf3694d40d2!2sDynamic%20logix!5e0!3m2!1sen!2s!4v1667905363850!5m2!1sen!2s" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
      // if (CheckIncomingElementIsReplaceable(e)) {
      //   if (e.target && e.target.parentNode) {
      //     // reset property
      //     current_target_element.style.removeProperty("opacity");
      //     current_target_element.style.removeProperty("outline");
      //     current_target_element.style.removeProperty("outline-style");
      //     SaveActionToStack();
      //     e.target.parentNode.innerHTML = Drag_Element.innerHTML;
      //     return;
      //   }
      // }
      if (FirstAttemp_Drop) {
        FirstAttemp_Drop = false;
        if (current_hovering_over_element && current_target_element) {
          // preprocessing of element
          let col = CheckColumnSpacing();
          let new_element = current_target_element.querySelector("#drag");
          if (new_element) {
            // new_element.removeAttribute("id");
            if (new_element.children && new_element.children.length > 0) {
              if (new_element.children[0].tagName === "IMG") {
                new_element.children[0].removeAttribute("width");
              } else if (
                new_element.children[0].tagName === "DIV" &&
                new_element.children[0].classList &&
                new_element.children[0].classList.contains("wb-iframe") &&
                !new_element.children[0].classList.contains("wb-iframe-map")
              ) {
                new_element.children[0].innerHTML = iframe_code;
              } else if (
                new_element.children[0].tagName === "DIV" &&
                new_element.children[0].classList &&
                new_element.children[0].classList.contains("wb-iframe") &&
                new_element.children[0].classList.contains("wb-iframe-map")
              ) {
                new_element.children[0].innerHTML = map_code;
              }
            }
          }

          // reset property
          current_target_element.style.removeProperty("opacity");
          current_target_element.style.removeProperty("outline");
          current_target_element.style.removeProperty("outline-style");

          // get element location
          let location = [...current_target_element.children].indexOf(
            new_element,
            0
          );
          // saveactiontostack
          let copy = new_element.cloneNode(true);
          new_element.remove();
          SaveActionToStack("drop listener");
          if (CheckIncomingElementIsReplaceable(e)) {
            if (
              new_element &&
              new_element.classList &&
              new_element.classList.contains("wb-layout")
            ) {
              // noting
            } else {
              if (e.target && e.target.parentNode) {
                e.target.parentNode.innerHTML = new_element.innerHTML;
                return;
              }
            }
          }
          current_target_element.insertBefore(
            copy,
            current_target_element.children[location]
          );
          new_element = current_target_element.querySelector("#drag");
          new_element.removeAttribute("id");

          if (col >= 3) {
            let row = document.createElement("div");
            row.classList.add("row");
            let clone = new_element.cloneNode(true);
            if (clone.classList && clone.classList.contains("wb-layout")) {
              row.innerHTML = clone.innerHTML;
            } else {
              row.append(clone);
            }
            current_target_element.insertBefore(row, new_element);
            new_element.remove();
          }
          let targetSection = _find_section_of_element(current_target_element);
          ReRenderHtml(current_target_element, targetSection);

          current_target_element.style.removeProperty("opacity");
          current_target_element.style.removeProperty("outline");
          current_target_element.style.removeProperty("outline-style");
          current_target_element = "";
          current_hovering_over_element = "";
          previous_target_element = "";
          previous_hovering_over_element = "";
        }
      }
    });
  };

  const handleDragEndListener = (e) => {
    setTimeout(() => {
      let Drag_Element = GetDragElement();
      if (!Drag_Element) return;
      if (current_hovering_over_element && previous_target_element) {
        if (current_target_element.querySelector("#drag")) {
          current_target_element.querySelector("#drag").remove();
        }
        let targetSection = _find_section_of_element(current_target_element);
        ReRenderHtml(current_target_element, targetSection);
        current_target_element.style.removeProperty("opacity");
        current_target_element.style.removeProperty("outline");
        current_target_element.style.removeProperty("outline-style");
        current_target_element = "";
        current_hovering_over_element = "";
        previous_target_element = "";
        previous_hovering_over_element = "";
      }
    }, 500);
  };

  useEffect(() => {
    document.addEventListener("dragend", handleDragEndListener, true);
    return () => {
      document.removeEventListener("dragend", handleDragEndListener, true);
    };
  }, []);

  const handleImageDropListener = (e) => {
    setTimeout(() => {
      handleDropUserIMG(e);
    }, 500);
  };
  useEffect(() => {
    document.addEventListener("drop", handleImageDropListener, true);

    return () => {
      document.removeEventListener("drop", handleImageDropListener, true);
    };
  }, []);

  // drag and drop listners end
  const AddElementInTargetPlace = (e) => {
    if (
      previous_target_element === current_target_element &&
      previous_hovering_over_element === current_hovering_over_element &&
      current_target_element != ""
    ) {
      return;
    }
    PushElementAccordingToMousePosition(e);
  };
  const PushElementAccordingToMousePosition = (e) => {
    // get grag element clone
    let Drag_Element = GetDragElement();

    let elementPos = current_hovering_over_element.getBoundingClientRect();

    if (e.clientY > (elementPos.bottom + elementPos.top) / 2) {
      // append at bottom of element
      if (current_hovering_over_element == current_target_element) {
        current_target_element.append(Drag_Element);
      } else {
        current_target_element.insertBefore(
          Drag_Element,
          current_hovering_over_element.nextSibling
        );
      }
    } else {
      // append at top of element
      if (current_hovering_over_element == current_target_element) {
        current_target_element.prepend(Drag_Element);
      } else {
        current_target_element.insertBefore(
          Drag_Element,
          current_hovering_over_element
        );
      }
    }
  };
  const GetDragElement = () => {
    let Drag_Element = [...document.getElementsByClassName("Drag-Element")];
    if (Drag_Element && Drag_Element.length > 0) {
      Drag_Element = Drag_Element[0].firstChild.cloneNode(true);
      return Drag_Element;
    }
    return false;
  };
  const RemovingStyleFromPreviousElement = () => {
    if (current_target_element !== previous_target_element) {
      if (previous_target_element && previous_target_element.style) {
        previous_target_element.style.removeProperty("opacity");
        previous_target_element.style.removeProperty("outline");
        current_target_element.style.removeProperty("outline-style");
      }

      // add style to new element
      if (current_target_element && current_target_element.style) {
        current_target_element.style["opacity"] = "0.6";
        current_target_element.style["outline"] = "black solid 2px";
        current_target_element.style["outline-style"] = "dashed";
      }
    }
  };
  const RemoveOldEleemntIfEnterInNewElement = () => {
    if (previous_hovering_over_element != current_hovering_over_element) {
      if (
        previous_hovering_over_element &&
        previous_hovering_over_element.nextSibling &&
        previous_hovering_over_element.nextSibling.id &&
        previous_hovering_over_element.nextSibling.id == "drag"
      ) {
        previous_hovering_over_element.nextSibling.remove();
      }
      if (
        previous_hovering_over_element &&
        previous_hovering_over_element.previousSibling &&
        previous_hovering_over_element.previousSibling.id &&
        previous_hovering_over_element.previousSibling.id == "drag"
      ) {
        previous_hovering_over_element.previousSibling.remove();
      }
    }
  };
  const CheckColumnSpacing = () => {
    if (current_hovering_over_element && current_target_element) {
      let element = current_target_element;
      while (element && element.parentNode) {
        if (element.parentNode.tagName === "SECTION") {
          break;
        }
        element = element.parentNode;
      }

      let elementPos = element.getBoundingClientRect();
      let current_target_elementPos =
        current_target_element.getBoundingClientRect();

      console.log(
        "Total Coloumns Contain ",
        GetPercentageWidth(
          current_target_elementPos.right - current_target_elementPos.left,
          elementPos.right - elementPos.left
        )
      );
      return GetPercentageWidth(
        current_target_elementPos.right - current_target_elementPos.left,
        elementPos.right - elementPos.left
      );
    }
  };
  const GetPercentageWidth = (element, container) => {
    if (element <= container) {
      return parseInt(((element / container) * 100) / 8);
    } else {
      return 12;
    }
  };
  const SwapElementPositionAccordingTOMouse = (e, Drag_Element) => {
    let exist = current_target_element.querySelector("#drag");
    let elementPos = current_hovering_over_element.getBoundingClientRect();

    if (e.clientY > (elementPos.bottom + elementPos.top) / 2) {
      // append at bottom of element
      if (current_hovering_over_element == current_target_element) {
        if (
          !(
            current_target_element.children &&
            current_target_element.children.length > 0 &&
            current_target_element.children[
              current_target_element.children.length - 1
            ].id &&
            current_target_element.children[
              current_target_element.children.length - 1
            ].id == "drag"
          )
        ) {
          current_target_element.append(Drag_Element);
          exist && exist.remove();
        }
      } else {
        if (
          !(
            current_hovering_over_element &&
            current_hovering_over_element.nextSibling &&
            current_hovering_over_element.nextSibling.id &&
            current_hovering_over_element.nextSibling.id == "drag"
          )
        ) {
          current_target_element.insertBefore(
            Drag_Element,
            current_hovering_over_element.nextSibling
          );
          exist && exist.remove();
        }
      }
    } else {
      // append at top of element
      if (current_hovering_over_element == current_target_element) {
        if (
          !(
            current_target_element.children &&
            current_target_element.children.length > 0 &&
            current_target_element.children[0].id &&
            current_target_element.children[0].id == "drag"
          )
        ) {
          current_target_element.prepend(Drag_Element);
          exist && exist.remove();
        }
      } else {
        if (
          !(
            current_hovering_over_element &&
            current_hovering_over_element.previousSibling &&
            current_hovering_over_element.previousSibling.id &&
            current_hovering_over_element.previousSibling.id == "drag"
          )
        ) {
          current_target_element.insertBefore(
            Drag_Element,
            current_hovering_over_element
          );
          exist && exist.remove();
        }
      }
    }
  };
  const handleAddElement = (e) => {
    if (current_target_element && current_hovering_over_element) {
      RemovingStyleFromPreviousElement();
      AddElementInTargetPlace(e);
    }
  };

  // element handler
  const handleEnterDragID = (e) => {
    let check = e.target;
    let result = false;
    // hover over drag element then return as default
    while (check && check.parentNode) {
      if (check.id && check.id == "drag") {
        console.log("Found DragID");
        result = true;
      }
      if (check.tagName == "SECTION") {
        break;
      }
      check = check.parentNode;
    }

    return result;
  };
  const handleEnterFORM = (e) => {
    let element = e.target;
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element && element.tagName === "FORM") {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    if (element_found) {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
    }
    return element_found;
  };
  const handleEnterEditor = (e) => {
    let element = e.target;
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element.classList && element.classList.contains("wb-editor-block")) {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    if (element_found) {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
    }
    return element_found;
  };
  const handleEnterIMG = (e) => {
    let element = e.target;
    let element_found = false;
    if (element && element.tagName === "IMG") {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
      element_found = true;
    }
    return element_found;
  };
  const handleEnterBUTTON_or_ANCHOR = (e) => {
    let element = e.target;
    let element_found = false;
    if (element && (element.tagName === "BUTTON" || element.tagName === "A")) {
      if (element.parentNode.tagName == "A") {
        element = element.parentNode;
      }
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
      element_found = true;
    }
    return element_found;
  };
  const handleEnterI = (e) => {
    let element = e.target;
    let element_found = false;
    if (element && element.tagName === "I") {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
      element_found = true;
    }
    return element_found;
  };
  const handleEnterROW = (e) => {
    let element = e.target;
    let element_found = false;
    while (element && element.parentNode) {
      if (element.tagName === "SECTION") {
        break;
      }
      if (element.classList && element.classList.contains("row")) {
        element_found = true;
        break;
      }
      element = element.parentNode;
    }
    if (element_found) {
      console.log(`Found ${element.tagName}`);

      // changinf previous values
      previous_target_element = current_target_element;
      previous_hovering_over_element = current_hovering_over_element;
      // changing new values
      current_target_element = element.parentNode;
      current_hovering_over_element = element;
    }
    return element_found;
  };

  // <---------------Drag And Drop Image From User Gallery----------------->

  const CheckIncomingElementIsIMG = (e) => {
    let Element = GetUserGalleryIMG();
    if (!Element) return;
    e.preventDefault();
  };
  const CheckIncomingElementIsReplaceable = (e) => {
    let result = false;
    if (
      e.target &&
      e.target.classList &&
      e.target.classList.contains("wb-replaceable-element")
    ) {
      result = true;
    }
    return result;
  };
  const handleDropUserIMG = (e) => {
    if (e.target.tagName === "IMG") {
      let Element = GetUserGalleryIMG();
      if (!Element) return;
      let element_exist_in_editable_section = false;
      let element = e.target;
      while (element && element.parentNode) {
        if (element.tagName === "SECTION") {
          element_exist_in_editable_section = true;
        }
        element = element.parentNode;
      }

      if (element_exist_in_editable_section) {
        e.target.src = Element.src;
        e.target.alt && e.target.alt.length > 0 ? "" : (e.target.alt = "Image");
        RemoveUserGalleryClass();
      }
    }
  };
  const GetUserGalleryIMG = () => {
    let Element = [...document.getElementsByClassName("user-gallery-img")];
    if (Element && Element.length > 0) {
      Element = Element[0].cloneNode(true);
      return Element;
    }
    return false;
  };
  const RemoveUserGalleryClass = () => {
    let data = [...document.getElementsByClassName("user-gallery-img")];
    for (let a = 0; a < data.length; a++) {
      data[a].classList.remove("user-gallery-img");
    }
  };

  // <---------------Drag And Drop End----------------->

  //   function getEventListeners(element) {
  //     const listeners = {};
  //     // Iterate through common event properties (not exhaustive)
  //     for (const event of ["Click", "mouseover", "mouseout", "mouseup", "mousedown"]) {
  //         if (element[`on${event}`]) {
  //             listeners[event] = element[`on${event}`];
  //         }
  //     }
  //     return listeners;
  // }

  function getEventListeners(element) {
    const listeners = {};
    // Use `eventListeners` property (if available)
    if (element.eventListeners) {
      console.log(
        element.eventListeners,
        "listenerlistenerlistener element.eventListeners"
      );

      for (const listener of element.eventListeners) {
        const type = listener.type;
        if (!listeners[type]) {
          listeners[type] = [];
        }
        listeners[type].push(listener);
      }
    } else {
      // Fallback for older browsers
      for (const eventName in element) {
        if (eventName.startsWith("on")) {
          const type = eventName.slice(2).toLowerCase();
          listeners[type] = element[type];
          console.log(
            element[type],
            element[eventName],
            listeners,
            "listenerlistenerlistener startsWith(on)"
          );
        }
      }
    }
    return listeners;
  }

  const addListener = (element) => {
    const tag = element.tagName;
    const name = element.name;

    // let listener = getEventListeners(element);
    // console.log(listener,"listenerlistenerlistener")
    if (clickListenerElementSet.has(element)) {
      return;
    } else {
      setClickListenerElementSet((prevSet) => new Set(prevSet).add(element));
    }

    // if(listener.click.length !== 0){
    //   return
    // }
    // if(listener.click){
    //   return
    // }
    if (tag === "path" || name === "not-editable") {
      return;
    }

    if (
      element.tagName === "BUTTON" &&
      element.classList.contains("wp-add-element-button")
    ) {
      element.addEventListener("click", (e) => {
        setOpenReplaceElementMenu(true);
        setIsReplaceableElement(false);
        setSelectedElement(element);
        return false;
      });
      return false;
    }
    if (
      element.tagName === "BUTTON" &&
      element.classList.contains("wp-remove-block-button")
    ) {
      element.addEventListener("click", (e) => {
        setSelectedElement(element);
        handleRemoveBlock(element);
        return false;
      });
      return false;
    }

    element.addEventListener("click", (e) => {
      handleElementClick(e, element);
      return false;
    });
    // handleAddDragDropListener(element)

    // element.style.cssText =
    //   element.style.cssText + ' -webkit-user-drag:element;'
  };
  const getElementSection = (node) => {
    let section = null;
    if (node) {
      while (node.parentNode) {
        if (node.tagName == "SECTION") {
          section = node;
          break;
        }
        node = node.parentNode;
      }
    }
    return section;
  };
  const findElementById = (id) => {
    const element = document.getElementById(id);
    return element;
  };

  const findSectionElementById = (id) => {
    const element = document.getElementById(id);
    const section = element.getElementsByTagName("SECTION")[0];
    return section;
  };

  const updateLinks = (element, section) => {
    if (element.src && element.tagName === "IMG") {
      element.src = element.src.replace(
        window.location.origin,
        section.baseURL + "/" + section.section_name
      );
    }

    if (element.href && element.tagName === "LINK") {
      element.href = element.href.replace(
        window.location.origin,
        section.baseURL
      );
    }
  };

  /* ------------------------------------------------------
                State Handlers Functions 
  ------------------------------------------------------- */

  const getAllSections = () => {
    return sections;
  };

  const getSelectedSections = () => {
    return selectedSections;
  };

  const handleSectionOnSelect = async (
    section,
    index,
    target_s3,
    is_template = false,
    bussinessInfoToUpdateContet = ""
  ) => {
    let section_js;
    let section_css;
    let section_html;
    let unique_uuid = _generate_random_string(10);
    let section_name;

    if (s3baseUrl == target_s3) {
      // SaveActionToStack()

      let section_name = (unique_uuid = _generate_random_string(10));
      section_name = section._id;
      // fetch user html from s3
      section_html = await fetchFileFromURL(
        `${target_s3}/${section_name}/${SelectedPage}.html`
      );

      section_html = section_html.replaceAll("G_U_ID", "id_" + unique_uuid);

      // fetch user css from s3
      section_css =
        SelectedPage == "index"
          ? await fetchFileFromURL(`${target_s3}/${section_name}/css/style.css`)
          : await fetchFileFromURL(
              `${target_s3}/${section_name}/css/${SelectedPage}.css`
            );

      section_css = section_css.replaceAll(
        "../assets",
        `${s3builderSource}/${section_name}/assets`
      );

      // fetch user js from s3
      section_js = await fetchFileFromURL(
        `${target_s3}/${section_name}/js/${SelectedPage}.js`
      );

      section_js = section_js.replaceAll("G_U_ID", "id_" + unique_uuid);
    } else {
      is_template ? "" : SaveActionToStack("handleSectionOnSelect");
      unique_uuid = _generate_random_string(10);
      section_name = section._id;
      // fetch html from s3
      section_html = await fetchFileFromURL(
        `${target_s3}/${section_name}/index.html`
      );

      // console.log(section_html, "section_html");

      section_html = section_html.replaceAll("G_U_ID", "id_" + unique_uuid);

      // fetch css from s3
      section_css = await fetchFileFromURL(
        `${target_s3}/${section_name}/css/style.css`
      );

      section_css = section_css.replaceAll(
        "../assets",
        `${s3builderSource}/${section_name}/assets`
      );

      // fetch js from s3
      section_js = await fetchFileFromURL(
        `${target_s3}/${section_name}/js/index.js`
      );

      section_js = section_js.replaceAll("G_U_ID", "id_" + unique_uuid);
    }

    // console.log(section_html, "section_html");
    // console.log(section_css, "section_css");
    // console.log(section_js, "section_js");

    let el = document.createElement("div");
    el.innerHTML = section_html;

    const elements = el.getElementsByTagName("SECTION");

    const sections_length = elements.length;
    // If section_html has no sections

    if (sections_length === 0) {
      setIsPreviewLoading(false);
      return;
    }
    if (is_template || s3baseUrl != target_s3) {
      for (let i = 0; i < elements.length; i++) {
        updateNavBarIMG(elements[i]);
      }
    }

    if (sections_length === 1) {
      // if section_html has only one section
      let html_element = document.createElement("div");
      html_element.innerHTML = section_html;

      // const section_start_index = section_html.search("<section>");
      // const section_end_index = section_html.search("</section>") + 10;

      let selected_section = elements[0];

      // assign data first time
      if (s3baseUrl != target_s3) {
        selected_section.dataset._id = section._id;
        selected_section.dataset.section_id = unique_uuid;
        selected_section.dataset.section_title = section.name;
        selected_section.dataset.section_name = section._id;
        selected_section.id = unique_uuid;
      } else {
        section_css = GetSectionCss(
          section_css,
          selected_section.dataset.section_name
        );
      }

      // -------end

      const selected_section_html = selected_section.outerHTML;

      if (!selected_section_html) {
        return;
      }

      section = {
        ...section,
        baseURL: `${target_s3}`,
        section_id: unique_uuid,
        section_html: selected_section_html,
        section_name:
          s3baseUrl == target_s3
            ? selected_section.dataset.section_name
            : section_name,
        section_css,
        section_js,
        section_title: selected_section.dataset.section_title,
      };
      console.log("unique section id : ", unique_uuid);
      console.log("new added section is : ", section);

      if (section?.section_type?.name === "Book A Call") {
        setTimeout(() => {
          manipulate();
        }, 1500);
      }

      if (index !== undefined) {
        const items = Array.from(getSelectedSections());
        items.splice(index, 0, section);
        setSelectedSections(items);
        // scroll to the added section when user drag & drop a section
        setTimeout(() => {
          if (document.getElementById(section.section_id)) {
            document.getElementById(section.section_id).scrollIntoView();
            handleUpdateStateFromDOM(items);
          }
        }, 100);
        return;
      }

      setSelectedSections((c) => [...c, section]);

      // scroll to the added section when user click a section
      setIsPreviewLoading(false);
      setTimeout(() => {
        if (document.getElementById(section.section_id)) {
          document.getElementById(section.section_id).scrollIntoView();

          let sections_list = [...selectedSections, section];

          setSelectedSections([...sections_list]);
        }
      }, 100);
    }

    // if section_html has more then one sections
    if (sections_length > 1) {
      let element_sections = [];

      for (let i = 0; i < sections_length; i++) {
        let selected_section = elements[i];

        if (!selected_section.dataset) {
          const unique_uuid = _generate_random_string(10);
          // assign data first time
          selected_section.dataset._id = section._id;
          selected_section.dataset.section_id = unique_uuid;
          selected_section.dataset.section_title = section.name;
          selected_section.dataset.section_name = section._id;
          selected_section.id = unique_uuid;
          // -------end
        }

        let selected_section_html = selected_section.outerHTML;

        // if (bussinessInfoToUpdateContet.businessType !== "") {
        //   const postData = {
        //     query:
        //     selected_section_html +
        //       " " +
        //       `I have ${
        //         bussinessInfoToUpdateContet.businessType
        //       } based bussiness. My bussines name in ${
        //         bussinessInfoToUpdateContet.businessName
        //       } and my bussiness slogan in ${
        //         bussinessInfoToUpdateContet.businessSlogan
        //       }. my target audience/ target problem is ${
        //         bussinessInfoToUpdateContet.targetAudience
        //       }. ${
        //         bussinessInfoToUpdateContet.additionalBusinessInfo !== "" &&
        //         `some additional info of my bussines is ${bussinessInfoToUpdateContet.additionalBusinessInfo}`
        //       } .kindly update the content of the section according my bussiness info and also update the meta info of the page also. Each element which I given, return the responce in the same elements and same styling. if nested element exist with specifc styling or classes then those element should be included in new text having same meaning. if any anchor tag exist in the query then preserve the link you can change the innerText of anchor tag.`,
        //   };

        //   const resp = await _send_query_to_ai(postData);
        //   console.log(resp, "bussinessInfoToUpdateContet");
        //   if (resp.code === 200) {
        //     resp.content.map((msj) => {
        //       selected_section_html = msj.message.content;
        //     });
        //     console.log(selected_section_html, "bussinessInfoToUpdateContet");
        //   }
        // }

        let single_section = {
          section_id: selected_section.dataset.section_id,
          // section_id: uuidv4(),
          _id: selected_section.dataset._id,
          section_name: selected_section.dataset.section_name,
          section_html: selected_section_html,
          section_css: "",
          section_title: selected_section.dataset.section_title,
        };

        // css
        // single_section.section_css = await fetchFileFromURL(
        //   `${s3builderSource}/${single_section._id}/css/style.css`,
        // )

        // single_section.section_css = single_section.section_css.replaceAll(
        //   '../assets',
        //   `${s3builderSource}/${single_section._id}/assets`,
        // )

        // // js
        // single_section.section_js = await fetchFileFromURL(
        //   `${s3builderSource}/${single_section._id}/js/index.js`,
        // )

        single_section.section_css = GetSectionCss(
          section_css,
          selected_section.dataset.section_name
        );
        if (!single_section.section_css) {
          console.log("No Css now hitting api to get");
          single_section.section_css = await fetchFileFromURL(
            `${s3builderSource}/${single_section._id}/css/style.css`
          );
          single_section.section_css = single_section.section_css.replaceAll(
            "../assets",
            `${s3builderSource}/${single_section._id}/assets`
          );
        }
        single_section.section_css = single_section.section_css.trim();

        // js

        if (section_js && section_js != "/* No JS */") {
          single_section.section_js = GetSectionJS(
            section_js,
            selected_section.dataset.section_name
          );
          if (!single_section.section_js) {
            console.log("No JS now hitting api to get");
            single_section.section_js = await fetchFileFromURL(
              `${s3builderSource}/${single_section._id}/js/index.js`
            );
          }
        } else {
          single_section.section_js = "";
        }
        element_sections.push(single_section);
      }
      // console.log(element_sections, "element_sections");
      setIsPreviewLoading(false);
      setSelectedSections((c) => [...c, ...element_sections]);

      return;
    }
  };
  const updateNavBarIMG = async (updatedSection) => {
    let list = [...updatedSection.getElementsByClassName("navbar-brand")];
    for (let i = 0; i < list.length; i++) {
      let img = list[i].getElementsByTagName("img");
      if (img && img.length > 0) {
        let logo = GetProjectLogo();
        if (logo) {
          img[0].src = logo;
          img[0].style.height = "60px";
        }
      }
    }
  };
  const GetProjectLogo = () => {
    let icon = sessionStorage.getItem("project_logo");
    return icon && icon.length > 0 ? icon : "";
  };

  const handleChangeInEditableInputs = (key, val) => {
    console.log("My editor values are here ", val);
    setEditAbleInputs({
      ...editAbleInputs,
      [key]: val,
    });
  };

  const handleRemoveElement = async (
    element = null,
    isSaveActionToStack = true
  ) => {
    if (isSaveActionToStack) {
      let x = await SaveActionToStack("Remove element");
    }
    let targetElement = element ? element : selectedElement;
    let is_editor = checkIsEditorElement(targetElement);
    if (is_editor) {
      setEditorMenuOpen(false);
    } else {
      setRightMenuOpen(false);
    }
    if (
      targetElement.tagName == "INPUT" ||
      targetElement.tagName == "TEXTAREA" ||
      targetElement.tagName == "SELECT"
    ) {
      targetElement.parentNode.remove();
    } else {
      let target = targetElement;
      while (target.parentNode.children.length == 1) {
        target = target.parentNode;
      }
      console.log(target, "targetatagregttagatte");
      if (
        (!targetElement.tagName == "BUTTON" &&
          !targetElement.tagName == "I" &&
          !targetElement.tagName == "A") ||
        (target.children.length == 1 &&
          !target.classList.contains("wb-editor-block"))
      ) {
        if (
          targetElement.tagName === "IMG" &&
          (targetElement.parentNode?.classList.contains(
            "wb-card-hover-image"
          ) ||
            targetElement.parentNode?.classList.contains("image-hover"))
        ) {
          target.remove();
        } else {
          if (!_find_is_editor_element(target)) {
            add_HTML_on_remove_block(target);
          } else {
            target.remove();
          }
        }
      } else {
        target.remove();
      }
    }
    setSelectedSections([...selectedSections]);
  };

  const handleRemoveElementAndCheckCarousel = (element) => {
    let targetElement = element;
    if (element.classList.contains("carousel-item")) {
      let parent_element = targetElement.parentNode;

      var childElements = parent_element.children;
      for (var i = 0; i < childElements.length; i++) {
        childElements[i].classList.remove("active");
      }

      let carouselElement = _find_element_has_class(targetElement, "carousel");
      let carouselId = carouselElement.getAttribute("id");
      if (childElements.length === 2) {
        let elementsToRemove = carouselElement.querySelectorAll(
          '[data-bs-target="#' + carouselId + '"]'
        );
        console.log(carouselElement, elementsToRemove, "ueueueuueueu");
        elementsToRemove.forEach(function (element) {
          element.parentNode.removeChild(element);
        });
      }
      let targetIndex = 0;
      // Check if the targetChild has a next sibling
      if (targetElement.nextElementSibling) {
        targetElement.nextElementSibling.classList.add("active");
        targetIndex = getIndex(targetElement.nextElementSibling) - 1;
      } else if (targetElement.previousElementSibling) {
        // If no next sibling, check if there is a previous sibling
        targetElement.previousElementSibling.classList.add("active");
        targetIndex = getIndex(targetElement.previousElementSibling);
      }

      // Button Handling
      let carouselIndicators = carouselElement.getElementsByClassName(
        "carousel-indicators"
      );

      if (carouselIndicators.length > 0) {
        carouselIndicators = carouselIndicators[0];
        let lastChild = carouselIndicators.lastChild;
        // Check if the last child is an element node (to avoid removing text nodes, etc.)
        while (lastChild.nodeType !== 1 && lastChild.previousSibling) {
          lastChild = lastChild.previousSibling;
        }
        if (lastChild.nodeType === 1) {
          carouselIndicators.removeChild(lastChild);
        }
        var buttonElements = carouselIndicators.querySelectorAll("button");
        if (buttonElements.length <= 1) {
          carouselIndicators.remove();
          // Remove silder Button element
        } else {
          buttonElements.forEach(function (child, index) {
            child.dataset.bsSlideTo = index;
            if (index === targetIndex) {
              child.classList.add("active");
            } else {
              child.classList.remove("active");
            }
          });
        }
      }
      targetElement.remove();
    }
    if (element.classList.contains("carousel-inner")) {
      let targetToRemove = _find_element_has_class(targetElement, "carousel");
      targetToRemove.remove();
    } else {
      targetElement.remove();
    }
  };

  function getIndex(element) {
    var index = 0;
    while ((element = element.previousElementSibling) !== null) {
      index++;
    }
    return index;
  }
  const handleRemoveBlock = async (element) => {
    handleRemoveElementAndCheckCarousel(element.parentNode.parentNode);
    setSelectedSections([...selectedSections]);
  };

  const add_HTML_on_remove_block = (targetElement) => {
    targetElement.innerHTML = `<div class="d-flex flex-column justify-content-center align-items-center">
    <button class="rounded-pill px-4 py-2 wp-mb-12 wp-add-element-button" style="width: fit-content;background-color: #022859;border: none;color: #ffffff;">
       Add Element
     </button>
    <button class="rounded-pill px-4 py-2 wp-remove-block-button" style="width: fit-content;border: 1px solid #022859;color: #022859;background-color: #dee2e6;">
       Remove Block
     </button>
 </div>`;
  };

  const handleUpdateStateFromDOM = (updatedList = null) => {
    // console.log(updatedList, "before update");
    if (updatedList == null) {
      updatedList = selectedSections;
    }
    const updatedHtml = updatedList.map((sec) => {
      const updated_html = findElementById(sec.section_id).firstChild.innerHTML;
      return {
        ...sec,
        section_html: updated_html,
      };
    });
    // console.log(updatedHtml, "updatedHtml");
    setSelectedSections(updatedHtml);
    return updatedHtml;
  };

  const handleRemoveSelection = (id) => {
    SaveActionToStack("remove section");
    setUseEditingProject(true);
    const updatedList = selectedSections.filter((sec) => sec.section_id !== id);
    handleUpdateStateFromDOM(updatedList);
  };
  const handleChangeDuplicateId = (element) => {
    changeIdOfElement(element);

    const child = element.children.length;
    if (child > 0) {
      for (let i = 0; i < child; i++) {
        let childofElement = element.children[i];

        if (childofElement.children.length > 0) {
          handleChangeDuplicateId(childofElement);
        } else {
          changeIdOfElement(childofElement);
        }
      }
    }
  };
  const handleChangeAccordianDataSetID = (element, unique_uuid, _id) => {
    let parent = element.parentNode;
    for (let i = 0; i < parent.childElementCount; i++) {
      let sibling = parent.children[i];
      let elementObject = {
        htmlElement: sibling.outerHTML,
      };
      const string = JSON.stringify(elementObject);
      let newString = string;
      newString = newString
        .replace(
          `data-bs-target=\\"#${_id}\\"`,
          `data-bs-target=\\"#${unique_uuid}\\"`
        )
        .replace(
          `data-bs-target=\\'#${_id}\\'`,
          `data-bs-target=\\'#${unique_uuid}\\'`
        );
      const changedElementObject = JSON.parse(newString);
      sibling.outerHTML = changedElementObject.htmlElement;
    }
  };

  const changeIdOfElement = (element) => {
    const unique_uuid = _generate_random_string(5);
    const _id = element.getAttribute("id");
    if (_id) {
      element.setAttribute("id", unique_uuid);
      const data_set = element.getAttribute("data-bs-parent");
      if (data_set) {
        handleChangeAccordianDataSetID(element, unique_uuid, _id);
      }
    }
    const ModalButtonElement = element.getAttribute("data-bs-toggle");
    if (ModalButtonElement === "modal") {
      const target = element.getAttribute("name");
      element.setAttribute("data-bs-target", "#myModal_id_" + unique_uuid);
      element.setAttribute("name", "myModal_id_" + unique_uuid);
      handleVideoModalElement(target, unique_uuid);
    }
  };
  const handleVideoModalElement = (target, unique_uuid) => {
    const modalElement = document.getElementById(target);
    let newModalElement = modalElement.cloneNode(true);
    newModalElement.setAttribute("id", `myModal_id_${unique_uuid}`);
    console.log(modalElement, newModalElement);
    modalElement.insertAdjacentElement("afterend", newModalElement);
  };

  const handleDuplicateSection = async (section) => {
    SaveActionToStack("Duplicate Section");
    let targetIndex;
    // create copy of section
    let newsection = { ...section };
    const allsections = selectedSections.map((sec, i) => {
      if (sec.section_id === section.section_id) {
        targetIndex = i;
      }
      return sec;
    });

    // generate unique section id
    const unique_uuid = _generate_random_string(10);
    console.log(unique_uuid);

    // get section id and html to replace with update data
    const prev_section_id = newsection.section_id;
    let new_html = newsection.section_html;
    // replace prev section id and html with new
    newsection.section_html = new_html.replaceAll(prev_section_id, unique_uuid);
    newsection.section_id = unique_uuid;

    // console.log(section, "old section with old id");
    // console.log(newsection, "new section with new id");

    // update selection list
    allsections.splice(targetIndex + 1, 0, newsection);
    setSelectedSections(allsections);

    // scroll to the added section when user drag & drop a section
    setTimeout(() => {
      if (document.getElementById(unique_uuid)) {
        document.getElementById(unique_uuid).scrollIntoView();
        handleUpdateStateFromDOM(allsections);
      }
    }, 100);
  };
  const handleUpSelection = (id) => {
    // console.log(id);
    let targetIndex;
    const updatedList = selectedSections.map((sec, i) => {
      if (sec.section_id === id) {
        targetIndex = i;
      }
      return sec;
    });

    if (targetIndex && targetIndex > 0) {
      SaveActionToStack("section up");

      const temp = updatedList[targetIndex - 1];
      updatedList[targetIndex - 1] = updatedList[targetIndex];
      updatedList[targetIndex] = temp;
      handleUpdateStateFromDOM(updatedList);
    }
  };

  const handleDownSelection = (id) => {
    // console.log(id);
    let targetIndex;
    const updatedList = selectedSections.map((sec, i) => {
      if (sec.section_id === id) {
        targetIndex = i;
      }
      return sec;
    });

    if (targetIndex !== null && targetIndex < updatedList.length - 1) {
      SaveActionToStack("section down");

      const temp = updatedList[targetIndex + 1];
      updatedList[targetIndex + 1] = updatedList[targetIndex];
      updatedList[targetIndex] = temp;
      // console.log(updatedList, "updatedList");
      // console.log(targetIndex, "targetIndex");
      handleUpdateStateFromDOM(updatedList);
    }
  };

  const handleUpdateSectionSettings = () => {
    // console.log("update section function");
  };

  const checkIsEditorElement = (targetNode) => {
    let is_editor = false;
    if (targetNode && targetNode.className && targetNode.className.length > 0) {
      const targetElementClasses = targetNode.className;
      let is_editor_index = targetElementClasses.search("wb-editor-block");
      if (is_editor_index >= 0) {
        is_editor = true;
      }
    }
    return is_editor;
  };

  const checkIsDisableElement = (targetNode) => {
    const targetElementClasses = targetNode.className;
    let is_disable_index = targetElementClasses.search("wb-disable");
    let is_disable = false;
    if (is_disable_index >= 0) {
      is_disable = true;
    }

    return is_disable;
  };

  const checkSPANofButton = (element) => {
    var currentElement = element.parentNode;
    if (currentElement && currentElement.tagName === "BUTTON") {
      return true;
    }
    while (currentElement && currentElement.tagName != "DIV") {
      if (currentElement && currentElement.tagName == "BUTTON") {
        return true;
      } else {
        currentElement = currentElement.parentNode;
      }
    }

    return false;
  };

  const getTextSelectedElement = () => {
    let element = selectedElement;

    if (element.childNodes.length === 0) {
      selectedElement.innerHTML = textValue;
      return element;
    }

    if (
      element.childNodes.length === 1 &&
      element.childNodes[0].nodeName === "#text"
    ) {
      return selectedElement;
    }

    let childNodeLength = element.childNodes.length;
    // Check if any child node is a span
    for (let i = 0; i < childNodeLength; i++) {
      const child = element.childNodes[i];
      if (child.nodeName === "SPAN") {
        return child;
      }
    }
    return selectedElement;
  };

  function replaceNewlinesWithBreaks(text) {
    if (text) {
      return text.replace(/\n/g, "<br />");
    } else {
      return "";
    }
  }

  const updateSelectedElementText = () => {
    let element = selectedElement;

    let textValue = replaceNewlinesWithBreaks(editAbleInputs.text);
    if (element.childNodes.length === 0) {
      selectedElement.innerHTML = textValue;

      return false;
    }
    if (
      element.childNodes.length === 1 &&
      element.childNodes[0].nodeName === "#text"
    ) {
      selectedElement.innerHTML = textValue;

      return false;
    }
    let childNodeLength = element.childNodes.length;
    // Check if any child node is a span
    for (let i = 0; i < childNodeLength; i++) {
      const child = element.childNodes[i];
      if (child.nodeName === "SPAN") {
        child.innerHTML = textValue;
        return true;
      }
    }
    return true;
  };

  const handleUpdateElement = (isSaveActiontoStack = true) => {
    if (isSaveActiontoStack) {
      SaveActionToStack("Update element");
    }
    const tag = selectedElement.tagName;
    let action = "remove";
    // handle A tag element
    if (tag === "A") {
      updateSelectedElementText();
      // selectedElement.innerHTML = editAbleInputs.text
      //   ? editAbleInputs.text
      //   : "";
      selectedElement.href = editAbleInputs.href ? editAbleInputs.href : "#";
      if (selectedElementAttributes.target) {
        action = "add";
      }
      if (selectedElementAttributes.book_a_call_event_id) {
        selectedElement.setAttribute(
          "book_a_call_event_id",
          selectedElementAttributes.book_a_call_event_id
        );
        selectedElement.classList.add("wp-book-a-call-modal-button");
      } else {
        selectedElement.removeAttribute("book_a_call_event_id");
        if (selectedElement.classList.contains("wp-book-a-call-modal-button")) {
          selectedElement.classList.remove("wp-book-a-call-modal-button");
        }
      }
      _dispatch_handle_href_target(selectedElement, action);
      selectedElement.href = selectedElementAttributes.href;
      if (selectedElementAttributes.class === "move-to-next") {
        selectedElement.classList.add(selectedElementAttributes.class);
      } else {
        if (selectedElement.classList.contains("move-to-next")) {
          selectedElement.classList.remove("move-to-next");
        }
      }
      setRightMenuOpen(false);
      return;
    }

    // for IMG tag element
    if (tag === "IMG") {
      selectedElement.src = editAbleInputs.src;
      setRightMenuOpen(false);
      return;
    }

    // for BUTTON tag element
    if (tag === "BUTTON") {
      updateSelectedElementText();
      return;
    }
    // if not match above

    let is_editor = checkIsEditorElement(selectedElement);
    if (is_editor) {
      const value = editorRef.current.getContent();
      selectedElement.innerHTML = value ? value : "-";
    } else {
      selectedElement.innerHTML = replaceNewlinesWithBreaks(
        editAbleInputs.text
      );
    }
    if (is_editor) {
      setEditorMenuOpen(false);
    } else {
      setRightMenuOpen(false);
    }

    // UndoAction()
  };
  const getEditAbleInputs = (key) => {
    return editAbleInputs[key];
  };

  const getSelectedElementTag = () => {
    return selectedElement.tagName;
  };

  const getEditModeState = () => {
    return editMode;
  };

  const getIsDevState = () => {
    return isDev;
  };

  const handleThemeClick = () => {
    setRightThemeMenuOpen(true);
  };

  const handleOpenSectionSettings = (section_id) => {
    setSelectedSectionSettingId(section_id);
    setSectionSettingsMenuOpen(true);
  };
  const handleOpenPlanSetting = (section_id) => {
    setSelectedSectionSettingId(section_id);
    setPlanSettingsMenuOpen(true);
  };
  const handleOpenFormSetting = (section_id) => {
    setSelectedSectionSettingId(section_id);
    // let element = document.getElementById(section_id)
    // let form = element.getElementsByTagName('form')[0]
    // let anchor = form.getElementsByTagName('a')[0]

    // anchor.scrollIntoView()
    // setTimeout(() => {

    let section = findSectionElementById(section_id);
    setSelectedElement(section.getElementsByTagName("FORM")[0]);
    setFormSettingsMenuOpen(true);

    // }, 400)
  };

  const isFieldNotAlwaysRequired = (formElement) => {
    let inputElements =
      formElement.tagName == "INPUT"
        ? [formElement]
        : formElement.getElementsByTagName("input");
    for (let i = 0; i < inputElements.length; i++) {
      if (requiredFieldsList.includes(inputElements[i].name)) {
        return false;
      }
    }

    return true;
  };

  const handleOpenEventSetting = (section_id) => {
    setSelectedSectionSettingId(section_id);
    setEventSettingsMenuOpen(true);
  };

  const manipulate = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the first day of the month
    let dayone = new Date(year, month, 1).getDay();

    // Get the last date of the month
    let lastdate = new Date(year, month + 1, 0).getDate();

    // Get the day of the last date of the month
    let dayend = new Date(year, month, lastdate).getDay();

    // Get the last date of the previous month
    let monthlastdate = new Date(year, month, 0).getDate();

    // Variable to store the generated calendar HTML
    let lit = "";

    // Loop to add the last dates of the previous month
    for (let i = dayone; i > 0; i--) {
      lit += `<li class="inactive">${monthlastdate - i + 1}</li>`;
    }

    // Loop to add the dates of the current month
    for (let i = 1; i <= lastdate; i++) {
      // Check if the current date is today
      let isToday =
        i < date.getDate() &&
        month === new Date().getMonth() &&
        year === new Date().getFullYear()
          ? "inactive"
          : "";

      lit += `<li class="${isToday} wb-disable">${i}</li>`;
      // if (isToday === "active") {
      //   handleShowTimeSlots(i);
      // }
    }

    // Loop to add the first dates of the next month
    for (let i = dayend; i < 6; i++) {
      lit += `<li class="inactive wb-disable">${i - dayend + 1}</li>`;
    }

    let currentDate = document.getElementsByClassName(
      "wb-calendar-current-date"
    );
    currentDate.length > 0 &&
      [...currentDate].forEach(
        (list) => (list.innerText = `${months[month]} ${year}`)
      );

    let datesUL = document.getElementsByClassName("wb-calendar-dates");

    datesUL.length > 0 &&
      [...datesUL].forEach((list) => (list.innerHTML = lit));
  };

  // top menus manipulations

  const get_nav_menu_element = () => {
    let is_found = true;
    const selected_section = findElementById(selectedSectionSettingId);

    if (!selected_section) {
      console.error("unable to find section with selectedSectionSettingId");
      is_found = false;
    }
    const nav_sections_list =
      selected_section?.getElementsByClassName("navbar-nav");

    if (nav_sections_list?.length === 0) {
      console.error("unable to find nav_sections_list with navbar-nav class");
      is_found = false;
    }

    const nav_section = nav_sections_list ? nav_sections_list[0] : null;

    return { nav_section, is_found };

    // let is_found = true;
    // let selected_section = findElementById(selectedSectionSettingId);

    // if (!selected_section) {
    //   selected_section = _find_section_of_element(selectedElement);
    // }
    // let nav_sections_list =
    //   selected_section?.getElementsByClassName("navbar-nav");

    // if (nav_sections_list?.length === 0) {
    //   selected_section = _find_section_of_element(selectedElement);
    //   nav_sections_list =
    //     selected_section?.getElementsByClassName("navbar-nav");
    //   if (nav_sections_list?.length === 0) {
    //     is_found = false;
    //   }
    // }

    // const nav_section = nav_sections_list ? nav_sections_list[0] : null;

    // return { nav_section, is_found };
  };

  const getTopMenuNavClasses = ({ class_list }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        class_list,
      };
      const classes = _dispatch_get_class_by_element_in_dom(payloadData);
      return classes;
    } else {
      return "";
    }
  };

  const updateTopMenuNavClasses = ({ value, class_list }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        value,
        class_list,
      };
      const classes = _dispatch_update_classes_by_element_in_dom(payloadData);
      return classes;
    } else {
      return "";
    }
  };

  const getTopMenuNavStyle = ({ property }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        property,
      };
      let children = nav_section.children;
      if (children) {
        children = children[0].children;
        if (children.length > 0) {
          return children[0].style[property];
        }
      }
      const classes = _dispatch_get_styles_by_element_from_dom(payloadData);
      // return classes
    } else {
      return "";
    }
  };

  const getSelectedElementStyleByProperty = ({ property }) => {
    const payloadData = {
      element: selectedElement,
      property,
    };
    const style = _dispatch_get_styles_by_element_from_dom(payloadData);
    return style;
  };

  const updateSelectedElementStyleByProperty = ({ property, value }) => {
    const payloadData = {
      element: selectedElement,
      property,
      value,
    };
    _dispatch_update_styles_by_element_in_dom(payloadData);
  };

  const updateTopMenuNavStyle = ({ property, value }) => {
    const { nav_section, is_found } = get_nav_menu_element();

    if (is_found) {
      const payloadData = {
        element: nav_section,
        property,
        value,
      };
      const classes = _dispatch_update_styles_by_element_in_dom(payloadData);

      for (let i = 0; i < nav_section.childElementCount; i++) {
        const element = nav_section.children[i];

        if (element.childElementCount === 0) {
          const payloadData = {
            element: element,
            property,
            value,
          };
          const classes =
            _dispatch_update_styles_by_element_in_dom(payloadData);
        } else {
          for (let j = 0; j < element.childElementCount; j++) {
            const child_element = element.children[j];
            console.log(child_element, "expected-a");
            if (child_element.childElementCount === 0) {
              const payloadData = {
                element: child_element,
                property,
                value,
              };

              const classes =
                _dispatch_update_styles_by_element_in_dom(payloadData);
            }
          }
        }
      }

      return "";
    } else {
      return "";
    }
  };

  // Get Ai Content For Sections
  const handleGetAIAContentForSections = async (
    sectionsHTML,
    bussinessInfo
  ) => {
    let selected_section_html = sectionsHTML;

    const minifiedHTML = await minify(selected_section_html, {
      collapseWhitespace: true,
      removeComments: true,
      minifyJS: true,
      minifyCSS: true,
    });

    // const minifiedHTML = selected_section_html;

    const {
      businessType,
      businessName,
      industryType,
      businessSlogan,
      additionalBusinessInfo,
      targetAudience,
      targetProblem,
      visitorsToDo,
      contentTone,
      seoKeywords,
    } = bussinessInfo;

    const postData = {
      query: `I have a ${businessType} based business of ${industryType}. Name of my business is ${businessName} and my business offer ${businessType} of  ${industryType}. ${
        businessSlogan !== ""
          ? `vision or mission of our business: ${businessSlogan}. `
          : ""
      } ${
        additionalBusinessInfo !== ""
          ? `Primary focus of our business: ${additionalBusinessInfo}. `
          : ""
      } Our target target audience are ${targetAudience}${
        ageLimit ? ` of age between ${ageLimit}` : ""
      }. ${
        targetProblem !== ""
          ? `We solve the ${targetProblem} problem/need of our audience.`
          : ""
      } ${
        visitorsToDo !== ""
          ? `I want visitors to do ${visitorsToDo} when they come to my website.`
          : ""
      } My section code is ${minifiedHTML},
      . kindly replace the old content of each element of the section with ${contentTone} content according my business information mentioned above and section type (mentioned in the section as section_title dataset). Replace lorem ipsum / dummy content with updated content. 
      Each element which I given, return the elements with updated content and same styling. if nested element exist with specifc styling or classes then those element should be included in new text having same meaning. if any anchor tag exist in the section then preserve the link but you can change the innerText of anchor tag according my bussiness information.
      if any courses or plans are present in the section then also update them. Please maintain the style of the section. Don't delete any element. Don't delete any carousel-item. Don't change any link/src/href.
      ${
        seoKeywords !== ""
          ? `Add these keywords/phrase in the content for SEO ${seoKeywords}`
          : ""
      }`,
    };

    // return {
    //   code: 200,
    //   message: selected_section_html,
    // };
    // return {
    //   code: 400,
    //   message: "selected_section_html",
    // };
    const resp = await _send_query_to_ai(postData);
    console.log(resp, "_send_query_to_ai_send_query_to_ai");
    if (resp.code === 200) {
      let updatedHtml = "";
      resp.content.map((msj) => {
        updatedHtml = msj.message.content;
      });
      let obj = {
        code: 200,
        html: updatedHtml,
      };
      return obj;
    } else {
      enqueueSnackbar(resp.message, {
        variant: "error",
      });

      let obj = {
        code: 400,
        message: resp.message,
      };
      return obj;
    }
  };
  const handleGetAIAContentForSectionsOnebyOne = async (
    section,
    bussinessInfo
  ) => {
    // let dataset = _dispatch_get_dataset_by_settingId(
    //   section.getAttribute("id")
    // );
    let selected_section_html = section.outerHTML;

    const minifiedHTML = await minify(selected_section_html, {
      collapseWhitespace: true,
      removeComments: true,
      minifyJS: true,
      minifyCSS: true,
    });

    const {
      businessType,
      businessName,
      industryType,
      businessSlogan,
      additionalBusinessInfo,
      targetAudience,
      ageLimit,
      targetProblem,
      visitorsToDo,
      contentTone,
      seoKeywords,
    } = bussinessInfo;
    const postData = {
      query: `I have a ${businessType} based business of ${industryType}. Name of my business is ${businessName} and my business offer ${businessType} of  ${industryType}. ${
        businessSlogan !== ""
          ? `vision or mission of our business: ${businessSlogan}. `
          : ""
      } ${
        additionalBusinessInfo !== ""
          ? `Primary focus of our business: ${additionalBusinessInfo}. `
          : ""
      } Our target audience is ${targetAudience}${
        ageLimit ? ` of age between ${ageLimit}` : ""
      }. ${
        targetProblem !== ""
          ? `We solve the ${targetProblem} problem/need of our audience.`
          : ""
      } ${
        visitorsToDo !== ""
          ? `I want visitors to do ${visitorsToDo} when they come to my website.`
          : ""
      } My sections code is ${minifiedHTML},
      . kindly replace the old content of each element of the section with ${contentTone} content according my business information mentioned above and section type (mentioned in the section as section_title dataset). Replace lorem ipsum / dummy content with updated content. 
      Each element which I given, return the elements with updated content and same styling. if nested element exist with specifc styling or classes then those element should be included in new text having same meaning. if any anchor tag exist in the section then preserve the link but you can change the innerText of anchor tag according my bussiness information.
      if any courses or plans are present in the sections then also update them. Please maintain the style of the section. Don't delete any element. Don't delete any carousel-item. Don't change any link/src/href. Don't add text in a icon button. return section code in prettier format.
      ${
        seoKeywords !== ""
          ? `Add these keywords/phrase in the content for SEO ${seoKeywords}`
          : ""
      }`,
    };

    const resp = await _send_query_to_ai(postData);
    console.log(resp, "_send_query_to_ai_send_query_to_ai");
    if (resp.code === 200) {
      let updatedHtml = "";
      resp.content.map((msj) => {
        updatedHtml = msj.message.content;
      });
      let obj = {
        code: 200,
        html: updatedHtml,
      };
      return obj;
    } else {
      enqueueSnackbar(resp.message, {
        variant: "error",
      });

      let obj = {
        code: 400,
        message: resp.message,
      };
      return obj;
    }
  };

  const EmptyAllSetStates = () => {
    setClickListenerElementSet(new Set());
    setRightClickListenerElementSet(new Set());
    setRowListenerElementSet(new Set());
    setSliderListenerElementSet(new Set());
    setNavListListenerElementSet(new Set());
    setListListenerElementSet(new Set());
    setImageListenerElementSet(new Set());
    setWbBoxListenerElementSet(new Set());
    setIFrameListenerElementSet(new Set());
    setFormListenerElementSet(new Set());
  };

  // end top menu manipulations

  const getSelectedSectionLists = () => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_lists_by_id_and_child(selectedSectionSettingId);
  };

  const getSelectedSectionFrames = () => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_i_frames_by_id(selectedSectionSettingId);
  };


  const find_and_dispatch_form_from_section = (id) => {
    const element = document.getElementById(id);
    if (element) {
      let list = [];
  
      let show_menu = true;
      let form = null 
      if(formElementId){
        form = element.querySelectorAll(`#${formElementId}`);
      }else{

        form = element.getElementsByTagName("form");
      }
  
      if (form.length === 0) return { form: form, status: false };
  
      const first = form[0][0];
  
      return { form: form, status: true };
    } else {
      return { form: null, status: false };
    }
  };

  const getSelectedSectionForm = () => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    return find_and_dispatch_form_from_section(selectedSectionSettingId);
  };
  const getSelectedSectionFormByID = (id) => {
    if (!id) {
      alert("No section selected!");
      return;
    }

    return find_and_dispatch_form_from_section(id);
  };
  const getSelectedSectionPlanByID = (id = null) => {
    id = id ? id : selectedSectionSettingId;
    if (!id) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_plan_card_by_id(id);
  };
  const getSelectedSectionType = (id = null, type) => {
    id = id ? id : selectedSectionSettingId;
    if (!id) {
      alert("No section selected!");
      return;
    }

    return _dispatch_find_section_type_by_id(id, type);
  };

  const updateSelectedSectionStyle = (property, value, child) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const payloadData = {
      id: selectedSectionSettingId,
      child: child,
      property: property,
      value: value,
    };
    _dispatch_update_styles_in_dom(payloadData);
  };

  const getSelectedSectionStyle = (property, child) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    const payloadData = {
      id: selectedSectionSettingId,
      child: child,
      property: property,
    };
    const value = _dispatch_get_styles_from_dom(payloadData);
    return value;
  };

  const getSelectedSectionheightwidth = (child) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const value = _dispatch_get_heightwidth_from_dom(
      selectedSectionSettingId,
      child
    );
    const val = {
      height: value.offsetHeight,
      width: value.offsetWidth,
    };
    return val;
  };

  const getSelectedSectionClass = ({ child, class_list }) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }
    const payloadData = {
      id: selectedSectionSettingId,
      child: child,
      class_list,
    };
    const classes = _dispatch_get_classes_from_dom(payloadData);
    return classes;
  };

  const updateSelectedSectionClass = (payload) => {
    if (!selectedSectionSettingId) {
      alert("No section selected!");
      return;
    }

    console.log(payload, "paylodclass");
    const payloadData = {
      id: selectedSectionSettingId,
      child: payload.child,
      class_list: payload.class_list,
      value: payload.value,
      identifier: payload.identifier ? payload.identifier : null,
    };

    _dispatch_update_classes_in_dom(payloadData);
  };

  const updateSelectedElementClass = (payload) => {
    if (!selectedElement) {
      alert("No element selected!");
      return;
    }
    // SaveActionToStack("updateSelectedElementClass");
    console.log(payload, "paylodclass");
    const payloadData = {
      element: selectedElement,
      ...payload,
    };

    _dispatch_update_classes_by_element_in_dom(payloadData);
  };

  const updateAttributeOfSelectedElement = (key, value) => {
    if (!selectedElement) {
      alert("No element selected!");
      return;
    }
    if (value == "" && selectedElement.hasAttribute(key)) {
      selectedElement.removeAttribute(key);
    }
    if (value !== "") {
      selectedElement.setAttribute(key, value);
    }
  };
  const updateAttributeOfSelectedSection = (key, value) => {
    let section = _dispatch_find_element_by_id_and_child(
      selectedSectionSettingId,
      0
    );
    if (!section) {
      alert("No element selected!");
      return;
    }
    if (value == "" && section.hasAttribute(key)) {
      section.removeAttribute(key);
    }
    if (value !== "") {
      section.setAttribute(key, value);
    }
  };

  const getSelectedElementClassByType = (payload) => {
    if (!selectedElement) {
      alert("No element selected!");
      return;
    }

    const payloadData = {
      element: selectedElement,
      ...payload,
    };

    const class_name = _dispatch_get_class_by_element_in_dom(payloadData);
    return class_name;
  };
  const getSelectedElement = () => {
    if (selectedElement) {
      return selectedElement;
    }
    console.error("no element selected!");
  };
  const getSelectedElementAttributes = (_element) => {
    let element = null;
    if (_element) {
      element = _element;
    } else {
      element = selectedElement;
    }
    if (element) {
      console.log(element, "elementelement");
      let type = element.tagName;
      const { naturalHeight, naturalWidth, height, width } = element;
      if (
        element.tagName == "IMG" &&
        (!element.attributes.imgheight || !element.attributes.imgwidth)
      ) {
        element.setAttribute("imgheight", naturalHeight);
        element.setAttribute("imgwidth", naturalWidth);
        if (element?.parentElement?.tagName === "A") {
          setElementsCopyToSessionStorage(element.parentElement);
        } else {
          setElementsCopyToSessionStorage(element);
        }
      }
      let attributes = Object.values(element.attributes);
      let data = [];
      attributes.forEach((attr) => {
        data.push({ name: attr.name, value: attr.value });
      });

      return { type: type, attributes: data };
    }
    console.error("no element selected!");
  };

  const updateSelectedElementAttributes = (attribute, value) => {
    if (selectedElement) {
      // selectedElement[attribute] = value;
      if (attribute === "required") {
        selectedElement.required = value;
      } else {
        selectedElement.setAttribute(attribute, value);
      }
    }
    console.log(selectedElement, "updateSelectedElementAttributes");
    console.error("no element selected!");
  };
  const isParentAnchorTag = (_element = null) => {
    let isParentAnchor = false;
    let element = null;
    if (_element) {
      element = _element;
    } else {
      element = getSelectedElement();
    }

    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "A") {
          isParentAnchor = true;
          if (element.tagName === "SPAN") {
            setSelectedElement(element.parentElement);
            setElementsCopyToSessionStorage(element.parentElement);
          }
          return isParentAnchor;
        }
      }
    }
    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "BUTTON") {
          element = element.parentNode;

          if (element.parentElement.nodeName === "A") {
            if (element.tagName === "SPAN") {
              setSelectedElement(element.parentElement.parentElement);
              setElementsCopyToSessionStorage(
                element.parentElement.parentElement
              );
            }
            isParentAnchor = true;
            return isParentAnchor;
          }
        }
      }
    }

    return isParentAnchor;
  };

  const isParentButtonTag = (_element = null) => {
    let isParentAnchor = false;
    let element = null;
    if (_element) {
      element = _element;
    } else {
      element = getSelectedElement();
    }
    if (element) {
      if (element.parentElement) {
        if (element.parentElement.nodeName === "BUTTON") {
          isParentAnchor = true;
          if (element.tagName === "SPAN") {
            setSelectedElement(element.parentElement);
            setElementsCopyToSessionStorage(element.parentElement);
          }
          return isParentAnchor;
        }
      }
    }
    return isParentAnchor;
  };

  const handleEditorPosition = (e) => {
    let winWidth = window.innerWidth;
    let cmWidth = 400;
    let x = winWidth / 2 > e.pageX ? "left" : "right";
    setIconEditorPosition(x);
    return x;
  };
  const handleElementClick = (e, element) => {
    e.preventDefault();
    // getEventListeners(element);
    setElementsCopyToSessionStorage(element);
    setSelectedElement(element);
    let is_editor = checkIsEditorElement(element);

    if (element && element.classList) {
      if (element.classList.contains("wb-replaceable-element")) {
        handleReplaceableElementClick(element);
        return;
      } else if (element.classList.contains("wb-stripe-buy-button")) {
        setOpenStripeButtonModal(true);
        return;
      }
    }
    if (is_editor) {
      // setEditorMenuOpen(true);
      ShowEditor(element, SaveActionToStack, checkHTMLExistorNotinStack);
    } else {
      if (element.tagName == "IMG") {
        if (element?.parentElement?.tagName === "A") {
          setElementsCopyToSessionStorage(element.parentElement);
        }
        setImageAnchorEl(e);
      } else if (element.tagName == "I") {
        setIconAnchorEl(e);
        if (element?.parentElement?.tagName === "A") {
          setElementsCopyToSessionStorage(element.parentElement);
        }
        handleEditorPosition(e);
      } else if (
        element.tagName == "BUTTON" ||
        element.tagName == "A" ||
        isParentAnchorTag(e.target) ||
        isParentButtonTag(e.target) ||
        isParentAnchorTag()
      ) {
        setButtonEditorAnchorEl(e);
        handleEditorPosition(e);
        console.log("buttonclickclickclcikclick");
      } else if (
        element.tagName === "INPUT" ||
        element.tagName === "TEXTAREA"
      ) {
        setRightMenuOpen(true);
      } else if (isEditText(element)) {
        setTextEditorAnchorEl(e);
        handleEditorPosition(e);
      } else {
        setRightMenuOpen(true);
      }
    }

    // if we click on anchor tag element
    if (element.tagName === "A") {
      // console.log(element.href);
      setEditAbleInputs({
        ...editAbleInputs,
        text: element.innerText,
        href: element.href
          .trim()
          .replace(window.location.pathname, "")
          .replace(window.location.origin, ""),
      });
      return;
    }

    // if we click on Icon tag element then set height width
    if (element.tagName === "I") {
      if (element.parentNode.tagName === "A") {
        setEditAbleInputs({
          ...editAbleInputs,
          height: e.target.parentNode.offsetHeight,
          width: e.target.parentNode.offsetWidth,
        });
      } else {
        setEditAbleInputs({
          ...editAbleInputs,
          height: e.target.offsetHeight,
          width: e.target.offsetWidth,
        });
      }
      return;
    }

    // if we click on IMG tag element
    if (element.tagName === "IMG") {
      // console.log(element.href);
      setEditAbleInputs({
        ...editAbleInputs,
        src: element.src,
        alt: element.alt,
        height: element.height,
        width: element.width,
        imageName: element.dataset.image_name ? element.dataset.image_name : "",
        imageType: element.src.slice(element.src.lastIndexOf(".") + 1),
      });
      return;
    }
    // else case

    // making editor div for editor

    if (is_editor) {
      setEditAbleInputs({
        ...editAbleInputs,
        text: element.innerHTML,
      });
      return;
    }
    setEditAbleInputs({
      ...editAbleInputs,
      text: element.innerText,
    });
  };

  const setElementsCopyToSessionStorage = (element) => {
    if (element) {
      sessionStorage.setItem("copySelectedElement", element?.outerHTML);
    }
  };

  const isEditText = (element) => {
    const tag = element.tagName;
    const stopping_tags = [
      "IMG",
      "I",
      "INPUT",
      "TEXTAREA",
      "SELECT",
      "BUTTON",
      "A",
    ];

    let status = true;

    stopping_tags.forEach((element) => {
      if (element === tag) {
        status = false;
      }
    });

    return status;
  };

  let List = [
    {
      value: "I",
      label: "Icon",
      icon: "simple-icons:iconify",
    },
    {
      value: "A",
      label: "Button",
      icon: "material-symbols:radio-button-checked-outline",
    },
    {
      value: "IMG",
      label: "Image",
      icon: "fluent:image-edit-16-regular",
    },
    {
      value: "BUTTON",
      label: "Button",
      icon: "material-symbols:radio-button-checked-outline",
    },
    {
      value: "INPUT",
      label: "Input",
      icon: "material-symbols:insert-text-outline",
    },
    {
      value: "TEXTAREA",
      label: "Textarea",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "SELECT",
      label: "Option",
      icon: "uil:list-ui-alt",
    },
    {
      value: "FORM",
      label: "Form",
      icon: "ant-design:form-outlined",
    },
  ];

  let spanElement = [
    {
      value: "SPAN",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "LABEL",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "P",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "H1",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "H2",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "H3",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "H4",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "H5",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
    {
      value: "H6",
      label: "Text",
      icon: "material-symbols:text-fields-rounded",
    },
  ];

  const handleReplaceableElementClick = () => {
    setOpenReplaceElementMenu(true);
    setIsReplaceableElement(true);
  };

  const handleReplaceListElements = (selected_element, current_element) => {
    let targetSection = _find_section_of_element(selected_element);
    selected_element.replaceWith(current_element);
    ReRenderHtml(current_element, targetSection);
  };

  const fetchFileFromURL = (url) => {
    return new Promise(async (resolve, reject) => {
      const resp = await fetch(url, myInit);
      if (resp.status === 200) {
        const data = await resp.text();
        resolve(data);
      } else {
        console.error(
          "<====================URL resp error====================>",
          resp
        );
        resolve("");
      }
    });
  };

  const validateElement = (element) => {
    let result = true;
    let tagName = element.tagName;
    let inVlalidElementList = [
      "BUTTON",
      "A",
      "I",
      "IMG",
      "INPUT",
      "TEXTAREA",
      "SELECT",
    ];
    if (inVlalidElementList.includes(tagName)) {
      result = false;
    }

    return result;
  };
  const RemoveRowBlock = (singleHtml) => {
    const wp_row_block_list = [
      ...singleHtml.getElementsByClassName("wp-row-block"),
      ...singleHtml.getElementsByClassName("wp-box-block"),
      ...singleHtml.getElementsByClassName("wp-list-block"),
      ...singleHtml.getElementsByClassName("wp-form-block"),
      ...singleHtml.getElementsByClassName("wp-iframe-block"),
      ...singleHtml.getElementsByClassName("wp-plan-card-block"),
    ];
    if (wp_row_block_list.length > 0) {
      for (let x = 0; x < wp_row_block_list.length; x++) {
        wp_row_block_list[x].remove();
      }
      singleHtml = RemoveRowBlock(singleHtml);
      // return singleHtml
    }
    return singleHtml;
  };

  function handleLazyLoadingElements(sectionHTML) {
    let elementsToProcess = ["iframe", "img"];

    if (elementsToProcess.some((tag) => sectionHTML.includes(`<${tag}`))) {
      let _div = document.createElement("div");
      _div.innerHTML = sectionHTML;

      elementsToProcess.forEach((tag) => {
        let elements = _div.getElementsByTagName(tag);
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i];
          if (!element.classList.contains("lazyLoading-" + tag)) {
            element.classList.add("lazyLoading-" + tag);
          }
          element.setAttribute("data-src", element.src);
          element.removeAttribute("src");
        }
      });

      sectionHTML = _div.innerHTML;
    }

    return sectionHTML;
  }

  const collectFinalHTML = () => {
    const updatedSectionsList = handleUpdateStateFromDOM(selectedSections);

    let collect_html = "";

    // remove all the elements contain wp-row-block class

    updatedSectionsList.map((section, index) => {
      let singleHtml = findElementById(section.section_id);
      [...singleHtml.getElementsByClassName("wb-stripe-buy-button")].map(
        (item) => {
          item.children[0].style.removeProperty("pointer-events");
        }
      );
      singleHtml = RemoveRowBlock(singleHtml);
      let sectionHTML = singleHtml.firstChild.innerHTML;

      // if (sectionHTML.includes("<iframe")) {
      //   let _div = document.createElement("div");
      //   _div.innerHTML = sectionHTML;

      //   let iframes = _div.getElementsByTagName("iframe");
      //   for (let i = 0; i < iframes.length; i++) {
      //     let iframe = iframes[i];
      //     if (!iframe.classList.contains("lazy-iframe")) {
      //       iframe.classList.add("lazy-iframe");
      //     }
      //     iframe.setAttribute("data-src", iframe.src);
      //     iframe.removeAttribute("src");
      //   }
      //   sectionHTML = _div.innerHTML;
      // }

      // lazy loading work commented when ever we need this, we will just  uncomment this

      // if (index > 1) {
      //   sectionHTML = handleLazyLoadingElements(sectionHTML);
      // }

      collect_html += "\n\n\t" + sectionHTML;
    });

    let Save_Data = true;
    // console.log(collect_html, "collect_html");
    if (
      collect_html.length == 0 &&
      UseEditingProject == false &&
      selectedSections.length > 0
    ) {
      Save_Data = false;
      setUseEditingProject(true);
    }
    const css = SelectedPage == "index" ? "style" : SelectedPage;

    const { stringOfFontLinks, stringOfFontClasses } =
      getFontLinksAndClasses(collect_html);
    // added project  id and page id
    collect_html = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>${
          rootValues.meta_title
            ? rootValues.meta_title
            : rootValues.project_name
        }</title>
        <link rel="icon" type="image/x-icon" href="${
          rootValues.fav_icon ? rootValues.fav_icon : ""
        }">
        <meta name="description" content="${
          rootValues.meta_description ? rootValues.meta_description : ""
        }" />
        <meta name="keywords" content="${
          rootValues.meta_keywords ? rootValues.meta_keywords : ""
        }" />

        <!--Strip Payment Script  start-->
        <!--<script src="https://js.stripe.com/v3/"></script>-->
        <!--Strip Payment Script  Ends-->


        <!--Social Sharing Cards  start-->

        <!--Google Card Data start-->
        <meta itemprop="name" content="${
          rootValues.google_social_sharing_name
            ? rootValues.google_social_sharing_name
            : ""
        }">
        <meta itemprop="description" content="${
          rootValues.google_social_sharing_description
            ? rootValues.google_social_sharing_description
            : ""
        }">
        <meta itemprop="image" content="${
          rootValues.google_social_sharing_image
            ? rootValues.google_social_sharing_image
            : ""
        }">
        <!--Google Card Data  ends-->

        <!-- Twitter Card data start -->
        <meta name="twitter:card" content="${
          rootValues.twitter_social_sharing_card
            ? rootValues.twitter_social_sharing_card
            : ""
        }">
        <meta name="twitter:title" content="${
          rootValues.twitter_social_sharing_title
            ? rootValues.twitter_social_sharing_title
            : ""
        }">
        <meta name="twitter:description" content="${
          rootValues.twitter_social_sharing_description
            ? rootValues.twitter_social_sharing_description
            : ""
        }">
        <meta name="twitter:image:src" content="${
          rootValues.twitter_social_sharing_image
            ? rootValues.twitter_social_sharing_image
            : ""
        }">
        <!-- Twitter Card data  ends-->

        <!-- Open Graph data start -->
        <meta property="og:title" content="${
          rootValues.facebook_social_sharing_title
            ? rootValues.facebook_social_sharing_title
            : ""
        }" />
        <meta property="og:type" content="${
          rootValues.facebook_social_sharing_type
            ? rootValues.facebook_social_sharing_type
            : ""
        }" />
        <meta property="og:url" content="${
          rootValues.facebook_social_sharing_url
            ? rootValues.facebook_social_sharing_url
            : ""
        }" />
        <meta property="og:image" content="${
          rootValues.facebook_social_sharing_image
            ? rootValues.facebook_social_sharing_image
            : ""
        }" />
        <meta property="og:description" content="${
          rootValues.facebook_social_sharing_description
            ? rootValues.facebook_social_sharing_description
            : ""
        }" />
        <!-- Open Graph data ends -->
        <!--Social Sharing cards  ends-->

        <!-- Google analytics header Script  Start-->
        ${getScript(
          rootValues,
          "google_analytics_script",
          rootValues.projectScripts,
          "google_analytics_header_script",
          "google_script_to_add",
          "header"
        )}
        <!-- Google analytics header Script  End -->
        <!-- Facebook Pixel header Script  Start-->
        ${getScript(
          rootValues,
          "facebook_header_script",
          rootValues.projectScripts,
          "facebook_pixels_header_script",
          "facebook_script_to_add",
          "header"
        )}
        <!-- Facebook Pixel header Script End -->
        <!-- Custom Header Script  Start-->
        ${getScript(
          rootValues,
          "custom_header_script",
          rootValues.projectScripts,
          "custom_scripts_header_script",
          "custom_script_to_add",
          ""
        )}
        <!-- Custom Header Script End -->


       
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
        <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />

      <!-- Using Carousel
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css"
        integrity="sha512-sMXtMNL1zRzolHYKEujM2AqCLUR9F2C4/05cdbxjjLSRvMQIciEPCQZo++nk7go3BtSuK9kfa/s+a4f4i5pLkw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
    <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css"
        integrity="sha512-tS3S5qG0BlhnQROyJXvNjeEM4UpMXHrQfTGmbQ1gKmelCxlSEBUaxhRBj/EFTzpbP4RVSrpEikbmdJobCvhE3g=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
    />  -->
      
     <!-- <link
      rel="stylesheet"
      href="${asserts_base_url}/asserts-db/css/fonts.css"
    /> -->
    <link
      rel="stylesheet"
      href="${asserts_base_url}/asserts-db/css/main.css"
    />
      
      <!-- CDN for Animation -->
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

     ${stringOfFontLinks}
      
      <link href="css/${css}.css" rel="stylesheet">
      <style>
      ${getRootValues()}
      ${stringOfFontClasses}

      </style>
      </head>
      <body>
      <div name="root-data-div" data-website_id="${rootValues._id}"

      data-page_id="${rootValues.page_id}" data-success_message="${
      rootValues.success_message ? rootValues.success_message : ""
    }" data-failure_message="${
      rootValues.failure_message ? rootValues.failure_message : ""
    }"
    
    data-stripe_public_key="${
      customerSubscriptionDetail
        ? customerSubscriptionDetail.public_key
          ? customerSubscriptionDetail.public_key
          : ""
        : ""
    }"
    data-page_created_at="${
      rootValues?.createdAt ? moment(rootValues?.createdAt).format("LLLL") : ""
    }"
    data-page_updated_at="${
      rootValues?.updatedAt ? moment(rootValues?.updatedAt).format("LLLL") : ""
    }"
    ></div>
        ${collect_html}


        <!-- Stripe Payment Button Script Start-->
        <!-- <script async="" src="https://js.stripe.com/v3/buy-button.js"></script>-->
        <!-- Stripe Payment Button Script Ends-->


        <!-- Google analytics Body Start-->
        ${getScript(
          rootValues,
          "body_script",
          rootValues.projectScripts,
          "google_analytics_body_script",
          "google_script_to_add",
          "footer"
        )}
        <!--Google analytics Body End -->

       
        <!-- Facebook Pixel Body Script  Start-->
        ${getScript(
          rootValues,
          "facebook_body_script",
          rootValues.projectScripts,
          "facebook_pixels_body_script",
          "facebook_script_to_add",
          "footer"
        )}
        <!-- Facebook Pixel body Script End -->
        <!-- Custom body Script  Start-->
        ${getScript(
          rootValues,
          "custom_body_script",
          rootValues.projectScripts,
          "custom_scripts_body_script",
          "custom_script_to_add",
          ""
        )}
        <!-- Custom body Script End -->

        <!-- <script src="https://code.jquery.com/jquery-3.6.0.js"
      integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script> -->
        <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      ></script>
      
      <!-- font-awesome 6 -->
      <script
        src="https://kit.fontawesome.com/e30259c958.js"
        crossorigin="anonymous"
      ></script>
      
      <!-- Carousel 
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js"
        integrity="sha512-bPs7Ae6pVvhOSiIcyUClR7/q2OAsRiovw4vAkX+zJbw3ShAeeqezq50RIIcIURq7Oa20rW2n2q+fyXBNcU9lrw=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      ></script> -->

      <script src="${s3builderSourceForFiles}/asserts-db/js/index.js" crossorigin="anonymous"></script>
      <script src="${s3builderSourceForFiles}/asserts-db/js/payment.js" crossorigin="anonymous"></script>
      <script src="js/${SelectedPage}.js"></script>
      
    </body>
    </html>
    `;
    // console.log(collect_html, "abcdef");

    return { collect_html, Save_Data };
  };

  const getScript = (
    rootValues,
    pageScriptName,
    projectScripts = null,
    projectScriptName,
    toAddName = "",
    noScript = ""
  ) => {
    let pageScript = "";
    if (rootValues[pageScriptName]) {
      pageScript = rootValues[pageScriptName];
    }
    let projectScript = "";
    if (projectScripts && projectScripts[projectScriptName]) {
      projectScript = projectScripts[projectScriptName];
    }

    let scriptToReturn = "";
    if (pageScript || projectScript) {
      if (rootValues[toAddName] === "project_and_page") {
        scriptToReturn = pageScript + projectScript;
      } else if (rootValues[toAddName] === "project") {
        scriptToReturn = projectScript;
      } else {
        scriptToReturn = pageScript;
      }
    } else {
      return scriptToReturn;
    }

    if (noScript === "header" && scriptToReturn) {
      return `<noscript data-wb_custom_added="true" data-wb_header_script="true">
      ${scriptToReturn}
      </noscript>`;
    } else if (noScript === "footer" && scriptToReturn) {
      return `<noscript data-wb_custom_added="true">${scriptToReturn}
    </noscript>`;
    } else {
      return scriptToReturn;
    }
  };

  const collectFinalCSS = () => {
    let collect_css = "";
    selectedSections.forEach((section) => {
      if (section.section_css) {
        collect_css += `/*----[[${section.section_name}]]---*/
        ${section.section_css}
        /*----${section.section_name}----*/
        \n\n`;
      }
    });
    let Save_CSS = true;
    if (
      collect_css.length == 0 &&
      UseEditingProject == false &&
      selectedSections.length > 0
    ) {
      Save_CSS = false;
      setUseEditingProject(true);
    } else {
      Save_CSS = true;
    }
    return {
      collect_css: collect_css ? collect_css : `/* No CSS */`,
      Save_CSS,
    };
  };

  const collectFinalJS = () => {
    let collect_js = "";
    selectedSections.forEach((section) => {
      collect_js += section.section_js;
    });

    return collect_js ? collect_js : `/* No JS */`;
  };

  const sectionListForSaveInPage = () => {
    let _array = [];

    selectedSections.forEach((section) => {
      _array.push({
        section_id: section.section_id,
        section_name: section.section_title,
      });
    });

    return _array;
  };

  const updateUserDesign = async (page_img, exitbuilder) => {
    // change these values
    console.log("HandleSavePage updateUserDesign Called");

    const ProjectData = {
      project_name: rootValues.project_name,
      image: rootValues.page_list
        ? rootValues.page_list.length == 1
          ? page_img
          : rootValues.page_list[0].image
        : page_img,
      is_approved: rootValues.is_approved,
      is_completed: rootValues.is_completed,
      is_published: rootValues.is_published,
      status: rootValues.status,
      project_pages_count: rootValues.page_list.length,
      payment_page_id: rootValues.payment_page_id,
    };

    const {
      page_name,
      page_slug,
      project,
      theme_heading_color,
      primary_color,
      secondary_color,
      primary_text_color,
      secondary_text_color,
      hover_text_color,
      hover_background_color,
      font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      theme_mode,
      meta_title,
      meta_description,
      google_analytics_script,
      body_script,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = rootValues;

    const listOfSection = sectionListForSaveInPage();
    const PageData = {
      page_name,
      page_slug,
      project,
      image: page_img,
      mode: theme_mode ? theme_mode : "light",
      theme_heading_color,
      theme_primary_color: primary_color,
      theme_secondary_color: secondary_color,
      text_primary_color: primary_text_color,
      text_secondary_color: secondary_text_color,
      hover_text_color,
      hover_background_color,
      text_font_family: font_family,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      meta_title: meta_title ? meta_title : "",
      meta_description,
      google_analytics_script,
      body_script,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
      sections: listOfSection,
    };
    // const resp = await _update_user_design_project(rootValues._id, ProjectData)

    // const page_resp = await _update_user_design_page(
    //   rootValues.page_id,
    //   PageData,
    // )

    console.log(
      "HandleSavePage _update_user_design_project && _update_user_design_page"
    );

    await Promise.all([
      _update_user_design_project(rootValues._id, ProjectData),
      _update_user_design_page(rootValues.page_id, PageData),
    ]).then(async (resp) => {
      if (resp[0].code == 200) {
        console.log("HandleSavePage _update_user_design_project Done");
      }
      if (resp[1].code == 200) {
        console.log("HandleSavePage _update_user_design_page Done");
      }
      if (resp[0].code == 200 && resp[1].code == 200) {
        sessionStorage.setItem("project", JSON.stringify(resp[0].project));
        sessionStorage.setItem("page", JSON.stringify(resp[1].page));
        rootValues.page_list.map((page, index) => {
          if (page._id == resp[1].page._id) {
            rootValues.page_list[index] = resp[1].page;
            setRootValues({ ...rootValues });
          }
        });

        if (exitbuilder == "projects") {
          navigate(`/projects/${rootValues._id}`, {
            state: {
              Project: {
                _id: rootValues._id,
                project_name: rootValues.project_name,
              },
            },
          });
        } else if (exitbuilder == "login") {
          // navigate("/login");
          GotoLoginPage();
        }
        // return resp
      } else {
        resp[0].code != 200 &&
          enqueueSnackbar(resp[0].message, { variant: "error" });
        resp[1].code != 200 &&
          enqueueSnackbar(resp[1].message, { variant: "error" });
      }
      return resp;
    });
  };

  const RedirectToClientPortal = (path) => {
    const baseURL = sessionStorage.getItem("client_base_url");
    const paramsPath = sessionStorage.getItem("source_link");
    let user = parseInt(sessionStorage.getItem("userType"));

    if (paramsPath !== "" && paramsPath !== null) {
      window.location.href = paramsPath;
    } else if (baseURL && user === userTypes.vissioon) {
      window.location.href = baseURL + "website-pages";
    } else if (baseURL) {
      window.open(baseURL + path);
    } else {
      window.open(client_side_url);
    }
  };

  const GotoLoginPage = () => {
    // window.location.href = client_side_url + "/" + "controller/logout";

    const baseURL = sessionStorage.getItem("client_base_url");
    const paramsPath = sessionStorage.getItem("source_link");
    let user = parseInt(sessionStorage.getItem("userType"));
    sessionStorage.clear();
    if (paramsPath !== "" && paramsPath !== null) {
      window.location.href = paramsPath;
    } else if (baseURL && user === userTypes.vissioon) {
      window.location.href = baseURL + "website-pages";
    } else if (baseURL) {
      window.location.href = baseURL + "/projects";
    } else {
      window.location.href = client_side_url;
    }
  };
  const ReRenderHtml = (targetElement = null, targetSection = null) => {
    if (document.getElementById("wb-button-for-remove-focus")) {
      document.getElementById("wb-button-for-remove-focus").click();
    }
    if (targetElement == null && targetSection == null) {
      selectedSections.map((section) => {
        targetElementForAddListener(
          findElementById(section.section_id),
          section
        );
      });
    } else {
      targetElementForAddListener(targetElement, targetSection);
    }
    let element = document.getElementById("preview-div");
    if (selectedSections.length > 0) {
      handleAddDragDropListener(element);
    }
    if (!element) return;
    addQuickOptionOnAllTags(targetElement);
    isSaveLoading == false &&
      InitializeTinyEditor(
        setEditorMenuOpen,
        editorMenuOpen,
        setAiWriterModal,
        handleEditorPosition,
        SaveActionToStack
      );
    targetElementForRightClickListener(element);
  };

  const downloadZip = async (name) => {
    const zip_path = `${s3baseUrl}/${name}/${name}.zip`;
    // let blob = await fetch(zip_path, myInit).then((res) => res.blob());

    // saveAs(blob, `${name}.zip`);
    let download_button = document.createElement("a");
    download_button.href = zip_path;
    download_button.click();
    return true;
  };

  const handleDownload = async (Project) => {
    const postData = {
      project_name: `${Project._id}`,
    };
    const resp = await _making_zip_of_project(postData);
    if (resp.code === 200) {
      await downloadZip(`${Project._id}`);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    return resp;
  };
  const setDefaultRootValues = (defaultValues) => {
    SaveActionToStack();
    setRootValues({ ...rootValues, ...defaultValues });
  };

  const FetchPageAndUpdatePageRootValues = async (
    page,
    arrayOfLabelsToChangeFromRootValues
  ) => {
    let page_html = await fetchFileFromURL(
      s3baseUrl + "/" + page.project + "/" + page.page_slug + ".html"
    );

    const page_resp = await _get_user_design_details_page(page._id);
    const {
      page_name,
      page_slug,
      project,
      image,
      mode,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      input_border_color,
      menu_color,
      menu_hover_color,
      meta_title,
      meta_description,
      google_analytics_script,
      body_script,
      text_font_family,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    } = page_resp.Page;
    let PageData = {
      page_name,
      page_slug,
      project,
      image,
      mode,
      theme_heading_color,
      theme_primary_color,
      theme_secondary_color,
      text_primary_color,
      text_secondary_color,
      hover_text_color,
      hover_background_color,
      theme_box_shadow,
      theme_h1_font_size,
      theme_h2_font_size,
      theme_h3_font_size,
      theme_h4_font_size,
      theme_p_font_size,
      theme_h1_font_weight,
      theme_h2_font_weight,
      theme_h3_font_weight,
      theme_h4_font_weight,
      theme_p_font_weight,
      theme_line_height,
      input_border_color,
      input_placeholder_color,
      input_text_color,
      input_background_color,
      menu_color,
      menu_hover_color,
      meta_title,
      meta_description,
      google_analytics_script,
      body_script,
      text_font_family,
      theme_h1_font_family,
      theme_h2_font_family,
      theme_h3_font_family,
      theme_h4_font_family,
    };

    await arrayOfLabelsToChangeFromRootValues.map((value) => {
      let starting_Index = page_html.indexOf(value.label);
      let ending_index = page_html.indexOf(";", starting_Index);
      let sliceOfExistingValue = page_html.slice(starting_Index, ending_index);

      page_html = page_html.replace(
        sliceOfExistingValue,
        `${value.label}: ${rootValues[value.valueName]}`
      );
      PageData = {
        ...PageData,
        [value.pageResponseLabel]: rootValues[value.valueName],
      };
    });

    const postData = {
      html: page_html,
      project_id: page.project,
      slug_name: page.page_slug,
    };

    const resp = await _update_user_design_page(page._id, PageData);
    if (resp.code == 200) {
      const updatePageHTMLResp = await _save_user_design_html(postData);
      if (updatePageHTMLResp.code == 200) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleChangeRootValue = (key, value) => {
    // SaveActionToStack();
    // Change in color mood from Quick selection
    if (key === "mode") {
      // If we select light we change background white and text black
      if (value === "light") {
        setRootValues({
          ...rootValues,
          theme_mode: "light",
          primary_color: "#FFFFFF",
          primary_text_color: "#000000",
          secondary_text_color: "#FFFFFF",
        });
        return;
      }

      // If we select light we change background black and text white
      if (value === "dark") {
        setRootValues({
          ...rootValues,
          theme_mode: "dark",
          primary_color: "#000000",
          primary_text_color: "#FFFFFF",
          secondary_text_color: "#FFFFFF",
        });
        return;
      }
      return;
    }

    // Change in theme color from Quick selection
    if (key === "theme") {
      setRootValues({
        ...rootValues,
        primary_color: value.primary_color, //base Color
        secondary_color: value.secondary_color, //Theme Color
        primary_text_color: value.primary_text_color,
        secondary_text_color: value.secondary_text_color,
        hover_background_color: value.secondary_text_color,
        hover_text_color: value.secondary_color,
        theme_heading_color: value.theme_heading_color,
        input_background_color: value.primary_color,
        input_border_color: value.primary_text_color,
        input_text_color: value.primary_text_color,
        input_placeholder_color: value.primary_text_color,
        theme_box_shadow: value.box_shadow_color,
        menu_color: value.secondary_text_color,
        menu_hover_color: value.secondary_color,
      });
      return;
    }

    setRootValues({ ...rootValues, [key]: value });
  };

  const setRootValuesFromProject = async (values, options) => {
    const dev = _is_dev() ? true : false;
    const user = _get_user();
    setIsDev(dev);
    // section will be shown to the developer
    if (dev) {
      setEditMode(true);
    } else {
      if (user?.type === 2) {
        setEditMode(false);
      }
    }
    setIsPreviewLoading(true);
    setRootValues(values);
    if (values.page_group_id !== "") {
      const resp = await _list_page_group(values.page_group_id);
      console.log(resp, "funnelgroupfunnelgroup");
      if (resp.code == 200) {
        setFunnelGroup(resp.page_group_pages_list);
        setFunnelDetails(resp.page_group_detail);
        if (resp.page_group_detail.page_group_type !== "generic_website")
          showItemInLeftDrawer("Funnel");
      }
    }
    sessionStorage.setItem("project_logo", values.logo);
    setSelectedSections([]);
    if (options.is_template) {
      if (options.updateContent) {
        handleSectionOnSelect(
          { _id: options.template },
          undefined,
          s3builderSource,
          true,
          options.businessInfo
        );
      } else {
        handleSectionOnSelect(
          { _id: options.template },
          undefined,
          s3builderSource,
          true
        );
      }
    } else {
      handleSectionOnSelect({ _id: values._id }, undefined, s3baseUrl);
    }
  };

  const getRootValues = () => {
    const root = `
    :root {
      --wb-website-theme-primary-color:${rootValues.primary_color};
      --wb-website-theme-secondary-color:${rootValues.secondary_color};
      --wb-website-hover-background-color:${rootValues.hover_background_color};

      --wb-website-text-heading_color:${rootValues.theme_heading_color};
      --wb-website-text-primary-color:${rootValues.primary_text_color};
      --wb-website-text-secondary-color:${rootValues.secondary_text_color};
      --wb-website-hover-text-color:${rootValues.hover_text_color};
      --wb-theme-box-shadow:${rootValues.theme_box_shadow};

      --wb-input-placeholder-color:${rootValues.input_placeholder_color};
      --wb-input-text-color:${rootValues.input_text_color};
      --wb-input-background-color:${rootValues.input_background_color};
      --wb-input-border-color:${rootValues.input_border_color};

      --wb-menu-color:${rootValues.menu_color};
      --wb-menu-hover-color:${rootValues.menu_hover_color};
      
      --wb-website-text-font-family:${rootValues.font_family};
      --wb-theme-h1-font-size:${rootValues.theme_h1_font_size};
      --wb-theme-h2-font-size:${rootValues.theme_h2_font_size};
      --wb-theme-h3-font-size:${rootValues.theme_h3_font_size};
      --wb-theme-h4-font-size:${rootValues.theme_h4_font_size};
      --wb-theme-p-font-size:${rootValues.theme_p_font_size};
      --wb-theme-h1-font-weight:${rootValues.theme_h1_font_weight};
      --wb-theme-h2-font-weight:${rootValues.theme_h2_font_weight};
      --wb-theme-h3-font-weight:${rootValues.theme_h3_font_weight};
      --wb-theme-h4-font-weight:${rootValues.theme_h4_font_weight};
      --wb-theme-p-font-weight:${rootValues.theme_p_font_weight};
      --wb-theme-h1-font-family:${rootValues.theme_h1_font_family};
      --wb-theme-h2-font-family:${rootValues.theme_h2_font_family};
      --wb-theme-h3-font-family:${rootValues.theme_h3_font_family};
      --wb-theme-h4-font-family:${rootValues.theme_h4_font_family};
      --wb-theme-p-font-weight:${rootValues.theme_p_font_weight};
      --wb-theme-line-height:${rootValues.theme_line_height};
      ${
        rootValues.mobile_font_size
          ? `--wb-theme-root-html-font-mobile:${
              rootValues.mobile_font_size + "px"
            }`
          : ""
      }

    }
    `;

    // console.log(rootValues, "rootValues");
    return root;
  };

  const getFontLinksAndClasses = (_html) => {
    let listOfFontObjects = [];
    let arrayofFonts = [
      rootValues.font_family,
      rootValues.theme_h1_font_family,
      rootValues.theme_h2_font_family,
      rootValues.theme_h3_font_family,
      rootValues.theme_h4_font_family,
    ];

    google_font_family_classes.map((fontObj) => {
      // space mean 'space after coma e.g 'Montserrat', sans-serif
      // this space is due to some temaplates fonts are saved with space after coma and some are with out coma
      let spaceAddedCssRule = fontObj.css_rule.replaceAll(`"`, `'`);
      let withoutSpaceCssRule = spaceAddedCssRule;
      if (spaceAddedCssRule.includes(",")) {
        let targetIndex = spaceAddedCssRule.indexOf(",") + 1;
        if (spaceAddedCssRule[targetIndex] != " ") {
          spaceAddedCssRule =
            spaceAddedCssRule.slice(0, targetIndex) +
            " " +
            spaceAddedCssRule.slice(targetIndex);
        } else {
          withoutSpaceCssRule =
            spaceAddedCssRule.slice(0, targetIndex) +
            spaceAddedCssRule.slice(targetIndex + 1);
        }
      }

      if (
        _html.includes(spaceAddedCssRule) ||
        _html.includes(fontObj.value) ||
        arrayofFonts.includes(spaceAddedCssRule) ||
        _html.includes(withoutSpaceCssRule) ||
        arrayofFonts.includes(withoutSpaceCssRule)
      ) {
        if (_html.includes(fontObj.value)) {
          fontObj = {
            ...fontObj,
            isClassToAdd: true,
          };
        }
        listOfFontObjects.push(fontObj);
      }
    });

    let stringOfFontLinks = "";
    let stringOfFontClasses = `.font-roboto {
      font-family: "Roboto", sans-serif;
    }`;

    listOfFontObjects.map((obj) => {
      let link = obj.import_link;
      link = link.slice(
        link.indexOf("http"),
        link.indexOf("display=swap") + 12
      );
      let linkString = `<link rel="stylesheet" href="${link}" />`;
      stringOfFontLinks += "\n\t" + linkString;
      if (obj.isClassToAdd && obj.value !== "") {
        stringOfFontClasses += `\n\n\t.${obj.value} {
      font-family: ${obj.css_rule};
     }`;
      }
    });

    return { stringOfFontLinks, stringOfFontClasses };
  };

  function handleOnDragEnd(result) {
    if (result?.destination?.droppableId !== "preview-sections") return;
    SaveActionToStack("handleOnDragEnd");
    if (
      result.destination.droppableId === "preview-sections" &&
      result.source.droppableId === "list-sections"
    ) {
      const section = JSON.parse(result.draggableId);

      if (section) {
        handleSectionOnSelect(
          section,
          result.destination.index,
          s3builderSource,
          true //added true so can save action should not be called as it already called on dragend
        );
      }
    }
    if (
      result.destination.droppableId === "preview-sections" &&
      result.source.droppableId === "preview-sections"
    ) {
      const items = Array.from(getSelectedSections());
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setSelectedSections(items);
    }
  }
  function getSelectedSectionDataset() {
    let dataset = _dispatch_get_dataset_by_settingId(selectedSectionSettingId);
    return dataset;
  }
  function updateSelectedSectionTitle(newTitle) {
    let dataset = _dispatch_get_dataset_by_settingId(selectedSectionSettingId);
    dataset.section_title = newTitle;
    // console.log("TITLE UPDATED ");
    const updatedSection = selectedSections.map((sec, i) => {
      if (i === selectedSectionsIndex) {
        sec.section_title = newTitle;
        return sec;
      } else {
        return sec;
      }
    });
    setSelectedSections(updatedSection);
  }
  function getSelectedSectionSettingId() {
    if (selectedSectionSettingId) {
      return selectedSectionSettingId;
    }
  }

  const getSectionsListOfPageFromHTML = async (pageSlug) => {
    let pageContent = await fetchFileFromURL(
      s3baseUrl + "/" + rootValues.project + "/" + pageSlug
    );
    if (!pageContent) {
      return [];
    }
    const parser = new DOMParser();
    const pageDoc = parser.parseFromString(pageContent, "text/html");
    const sectionsList = pageDoc.getElementsByTagName("SECTION");
    if (sectionsList.length > 0) {
      const sectionsArray = [];
      for (let section of sectionsList) {
        sectionsArray.push({
          section_name: section.getAttribute("data-section_title"),
          section_id: section.getAttribute("data-section_id"),
        });
      }
      return sectionsArray;
    } else {
      return [];
    }
  };
  useEffect(() => {
    if (selectedSectionSettingId) {
      const sectionElement = document.getElementById(selectedSectionSettingId);
      if (sectionElement) {
        const selected_section =
          sectionElement.getElementsByTagName("section")[0];
        setSelectedSectionElement(selected_section);
      }
    }
  }, [selectedSectionSettingId]);
  /* <===== margins & paddings handling ======> */
  const updateSelectedElementSpacing = (type, property, value) => {
    let element = getSelectedElement();
    if (!element) return;
    if (value != "" && value) {
      element.style.setProperty(
        `${type + "-" + property}`,
        `${value}rem`,
        "important"
      );
    }
    if (!value || value == "") {
      element.style.removeProperty(`${type + "-" + property}`);
    }
  };

  /* <===== parent A tag handling ======> */
  const updateParentLink = (newLink) => {
    // console.log(newLink, "at builder context");
    let parent = selectedElement.parentNode;
    if (parent.tagName === "A") {
      let link = parent.getAttribute("href");
      // console.log(link);
      parent.setAttribute("href", newLink);
    }
  };
  const updateParentLinkToFunnel = (attr) => {
    let value = attr.class;
    let parent = selectedElement.parentNode;
    if (parent.tagName === "A") {
      if (value === "move-to-next") {
        parent.classList.add(value);
      } else {
        if (parent.classList.contains("move-to-next")) {
          parent.classList.remove("move-to-next");
        }
      }
      // let link = parent.getAttribute("href");
      // // console.log(link);
      // parent.setAttribute("name", value);
    }
  };

  const updateParentLinkTarget = (linkTarget) => {
    let parent = selectedElement.parentNode;
    if (parent.tagName === "A") {
      if (linkTarget) {
        parent.setAttribute("target", linkTarget);
      } else {
        parent.removeAttribute("target");
      }
    }
  };

  const getParentLink = () => {
    let parent = selectedElement.parentNode;
    let href = parent.getAttribute("href");
    let target = parent.getAttribute("target");
    let classList = parent.getAttribute("class");
    return { href, target, classList };
  };

  const handlePublishProject = async (Project = null) => {
    console.log(Project, "handlePublishProject");

    setIsPublishLoading(true);

    const zipPostData = {
      project_name: `${Project ? Project._id : rootValues._id}`,
    };

    // const zip_resp = await _making_zip_of_project(zipPostData)
    // if (zip_resp.code === 200) {

    if (!Project && rootValues.domain === "") {
      enqueueSnackbar("Please add your domain from your Project settings", {
        variant: "error",
      });
      setIsPublishLoading(false);
      return false;
    }
    if (Project && Project.domain === "") {
      enqueueSnackbar("Please add your domain from your Project settings", {
        variant: "error",
      });
      setIsPublishLoading(false);
      return false;
    }

    // const postData = {
    //   project_id: `${rootValues._id}`,
    // }

    // const unzip_resp = await _make_unzip_file_of_project(postData)
    // if (unzip_resp.code === 200) {
    const postData = {
      project_id: `${Project ? Project._id : rootValues._id}`,
      domain: Project ? Project.domain : rootValues.domain,
    };

    // setTimeout(async () => {
    const publish_resp = await _publish_project_on_domain(postData);
    if (publish_resp.code === 200) {
      enqueueSnackbar("Project Published Successfully", {
        variant: "success",
      });

      setTimeout(() => {
        const domain = Project ? Project.domain : rootValues.domain;
        const URL = `http://${domain}`;
        window.open(URL, "_blank");
      }, 1000);

      setIsPublishLoading(false);
      return true;
    } else {
      setIsPublishLoading(false);
      enqueueSnackbar(publish_resp.message, { variant: "error" });
      return false;
    }
    // }, 3000)
    // } else {
    //   setIsPublishLoading(false)
    //   enqueueSnackbar(unzip_resp.message, { variant: 'error' })
    // }
    // } else {
    //   enqueueSnackbar(zip_resp.message, { variant: 'error' })
    //   setIsPublishLoading(false)
    //   return
    // }
  };
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.download = fileName;

    fakeLink.href = blob;
    fakeLink.click();
    fakeLink.remove();
  };
  const convert_https_to_http = async (html, from, to) => {
    if (html) {
      const image_list = html.getElementsByTagName("img");
      if (image_list.length > 0) {
        for (let index = 0; index < image_list.length; index++) {
          image_list[index].src = image_list[index].src.replace(from, to);
        }
      }
    }

    return true;
  };
  const convert_background_https_to_http = async (html, from, to) => {
    // if (html) {
    //   const section_list = html.getElementsByTagName("section");
    //   if (section_list.length > 0) {
    //     for (let index = 0; index < section_list.length; index++) {
    //       if (section_list[index].style.backgroundImage) {
    //         let result = section_list[index].style.backgroundImage.replace(
    //           from,
    //           to
    //         );
    //         // section_list[index].style.backgroundImage = result
    //       } else {
    //         let result = getComputedStyle(section_list[index])[
    //           "background-image"
    //         ].replace(from, to);
    //         section_list[index].style["background-image"] = result;
    //       }
    //     }
    //     // console.log(getComputedStyle(section_list[0])['background-image'])
    //   }
    // }
    return true;
  };
  function pause(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }
  const CheckModalOpen = async () => {
    setIsSaveLoading(true);
    let delay = 10;
    let list = [...document.getElementsByClassName("video-player-stop-event")];
    if (list && list.length > 0) {
      for (let item of list) {
        let targetId = item.getAttribute("name");
        let modalElement = document.getElementById(targetId);
        if (
          modalElement?.classList &&
          modalElement?.classList.contains("show")
        ) {
          item.click();
          delay = 1500;
        }
        // if (
        //   item &&
        //   item.parentNode &&
        //   item.parentNode.parentNode &&
        //   item.parentNode.parentNode.parentNode &&
        //   item.parentNode.parentNode.parentNode.parentNode &&
        //   item.parentNode.parentNode.parentNode.parentNode.classList &&
        //   item.parentNode.parentNode.parentNode.parentNode.classList.contains(
        //     "show"
        //   )
        // ) {
        //   item.click();
        //   delay = 1500;
        // }
      }
    }
    return delay;
  };
  const CheckChangeExsistInHTML = () => {
    RemoveContentEditable();
    const { collect_html, Save_Data } = collectFinalHTML();
    let status = false;
    if (collect_html.trim().length == LastSaveData.trim().length) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  const handleRemoveThirdPartyTags = () => {
    const list = ["grammarly-extension"];
    list.map((tag) => {
      let element_list = document.querySelectorAll(tag);
      Array.from(element_list)?.map((_element) => {
        _element.remove();
      });
    });
  };
  const handleSaveTemplateToServer = async ({
    preview = false,
    exitbuilder = false,
    mobile = false,
    tablet = false,
  }) => {
    console.log("HandleSavePage Click");
    setIsSaveLoading(true);
    sessionStorage.setItem("fav_icon", rootValues.fav_icon);

    let delay = await CheckModalOpen();
    await pause(delay);
    console.log("HandleSavePage DelayDone");

    RemoveContentEditable();
    console.log("HandleSavePage Remove Editable done");

    const { collect_html, Save_Data } = collectFinalHTML();
    console.log("HandleSavePage collectFinalHTML Done");

    setLastSaveData(collect_html);
    const final_html = collect_html;
    handleRemoveThirdPartyTags(collect_html);

    const { collect_css, Save_CSS } = collectFinalCSS();
    console.log("HandleSavePage collectFinalCSS Done");

    const final_css = collect_css;

    const final_js = collectFinalJS();
    console.log("HandleSavePage collectFinalJS Done");

    // // console.log(final_css, "final_css");
    // console.log(rootValues, "rootValues");
    // console.log(final_js, "final_js");
    const postData = {
      html: final_html,
      css: final_css,
      javascript: final_js,
      project_id: rootValues._id,
      slug_name: SelectedPage,
    };

    console.log(
      Save_Data,
      "finalPostData save data",
      Save_CSS,
      "finalPostData save css"
    );
    // console.log(postData, "finalPostData");
    if (Save_Data && Save_CSS) {
      console.log("HandleSavePage SaveData & SaveCSS Done");

      setIsSaveLoading(true);
      // const resp = await _save_user_design(postData)
      // const PageUpdateResp = await updateUserDesign(
      //   `${rootValues._id}/asserts/${rootValues.page_slug}`,
      // )
      console.log("HandleSavePage _save_user_design Called");
      const resp = await _save_user_design(postData);
      InitializeTinyEditor(
        setEditorMenuOpen,
        editorMenuOpen,
        setAiWriterModal,
        handleEditorPosition,
        SaveActionToStack
      );

      if (mobile) {
        setTimeout(async () => {
          setMobilePreview(true);
          setTabletPreview(false);
        }, 500);
      }

      if (tablet) {
        setTimeout(async () => {
          setTabletPreview(true);
          setMobilePreview(false);
        }, 500);
      }

      if (preview) {
        setTimeout(async () => {
          const URL = `${s3baseUrl}/${rootValues._id}/${SelectedPage}.html`;
          window.open(URL, "_blank");

          // take screenshot
        }, 500);
      }

      if (resp.code == 200) {
        console.log("HandleSavePage _save_user_design Done");

        // const file = await getHTMLImage('https', 'http')
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
        setIsSaveLoading(false);
      }

      let result = await Promise.all([
        updateUserDesign(
          `${rootValues._id}/asserts/${rootValues.page_slug}`,
          exitbuilder
        ),
      ]);

      console.log("HandleSavePage updateUserDesign Done");

      // = result[0];
      const PageUpdateResp = result[0];
      // const file = result[2]previewURL

      setPreviewURL(`${s3baseUrl}/${rootValues._id}/${SelectedPage}.html`);
      setTimeout(() => {
        handlePageThumbnail();
      }, 100);
    } else {
      console.log("HandleSavePage NotDone SaveData & SaveCSS");
      enqueueSnackbar("Access denied. Please Try Again", { variant: "error" });
      // enqueueSnackbar('Error from server Please reload', { variant: 'error' })
      // console.log("Hello")
    }
    setIsSaveLoading(false);

    setUseEditingProject(true);
    return true;
  };

  const handlePageThumbnail = async () => {
    console.log("HandleSavePage handlePageThumbnail called");
    const image_resp = await getHTMLImage("https", "http");
    console.log(image_resp, "image_respimage_resp");
    if (image_resp) {
      const page_image_resp = await _s3upload_with_name(
        rootValues._id,
        image_resp,
        rootValues.page_slug
      );
      console.log(page_image_resp, "UpdateImageUpdateImage");
      if (page_image_resp.code == 200) {
        console.log(
          "HandleSavePage handlePageThumbnail _s3upload_with_name Done"
        );

        if (
          parseInt(sessionStorage.getItem("userType")) === userTypes.vissioon
        ) {
          let postData = {
            path: page_image_resp.file_name,
          };
          let resp = await _vissioon_page_thumbnail_update(
            rootValues.page_id,
            postData
          );
          console.log(resp, "vissiionImageRespnce");
          console.log("HandleSavePage handlePageThumbnail done");
        }
      } else {
        enqueueSnackbar(page_image_resp.message, { variant: "error" });
        setIsSaveLoading(false);
      }
    }
  };

  const getHTMLImage = async (from, to, Get_image) => {
    let screenshotTarget = document.querySelector(".wb-preview-div");
    // // convert images url
    // const img_converstion_result = await convert_https_to_http(
    //   screenshotTarget,
    //   from,
    //   to,
    // )

    // convert background image url

    // const background__converstion_result = await convert_background_https_to_http(
    //   screenshotTarget,
    //   from,
    //   to,
    // )

    // if (img_converstion_result && background__converstion_result) {
    let target_image = await html2canvas(screenshotTarget, {
      allowTaint: true,
      useCORS: true,
      height: 600,
      // width: 100,
    })
      .then((canvas) => {
        return canvas.toDataURL("image/png", 1.0);
        // downloadImage(canvas.toDataURL(), 'abc3')
      })
      .catch((err) => {
        console.log(err, "Error in canvas convertion");
      });

    if (target_image) {
      const dataURI = target_image;

      var byteCharacters = atob(dataURI.split(",")[1]);
      var byteArrays = [];
      const contentType = "png";
      const sliceSize = 512;
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var myblob = new Blob(byteArrays, { type: contentType });
      return myblob;
      // downloadImage(target_image, 'abc')
    }

    // }
  };
  useEffect(() => {
    // Automatically Saving project very first time.
    if (!isPreviewLoading) {
      setTimeout(() => {
        if (document.getElementById("wb-save-button")) {
          document.getElementById("wb-save-button").click();
          GetImagesList();
          if (rootValues?.template) {
            GetTemplateImagesList();
          }
          GetStockImagesList();
          GetPexelsImagesList();
        }
      }, 1000);
    }
  }, [isPreviewLoading]);

  useEffect(() => {
    ReRenderHtml();
    handleSectionBackgroundVideoRun();
    // setnewsection([]);
  }, [selectedSections]);

  useEffect(() => {
    // remove focus from the element
    // by blur method on drawers open and close
    if (selectedElement) {
      selectedElement.blur();
    }
  }, [rightMenuOpen, editorMenuOpen]);
  useEffect(() => {
    CloseAllEditor();
  }, [
    editorMenuOpen,
    rightMenuOpen,
    OpenRowSetting,
    openSelectedBoxDiv,
    sectionSettingsMenuOpen,
    leftMenuOpen,
    useNavigate(),
  ]);

  const Check_Class_Available_in_element = (
    elementClasslist,
    classListToSearch
  ) => {
    let classname = "";
    classListToSearch.forEach((cl) => {
      elementClasslist.forEach((c) => {
        if (c === cl.value || c === cl?.extra_class) {
          classname = c;
        }
      });
    });
    return classname;
  };

  const change_Class_in_element = (ClassName, classList, element) => {
    classList.map((cl) => {
      element.classList.remove(cl.value);
    });
    if (ClassName !== "") {
      element.classList.add(ClassName);
    }
  };

  const handleupdateIMG = (val) => {
    SaveActionToStack();
    editAbleInputs.src = val;
    selectedElement.src = editAbleInputs.src;
  };

  const GetSectionCssAndReplacePath = async () => {
    let section_name = [];
    let PromiseList = [];
    getSelectedSections().map((section) => {
      PromiseList.push(
        fetchFileFromURL(
          `${s3builderSource}/${section.section_name}/css/style.css`
        )
      );
      section_name.push(section.section_name);
    });
    await Promise.all(PromiseList)
      .then((cssList) => {
        section_name.map((singleSectionID, index) => {
          cssList[index] = cssList[index].replaceAll(
            "../assets",
            `${s3builderSource}/${singleSectionID}/assets`
          );
        });
        try {
          getSelectedSections().map((section, index) => {
            selectedSections[index].section_css = cssList[index];
          });

          setSelectedSections([...selectedSections]);
          ReRenderHtml();
          console.log(cssList, "Fetching css of every section resp");
        } catch (error) {
          console.log(error, "Erorr in Updating css");
        }
      })
      .catch((error) => {
        console.log(error, "Error In updating Css");
      });
  };

  const handleUpateCss = async () => {
    // for all sections
    // GetSectionCssAndReplacePath()

    // for single section
    let section_id = findElementById(
      selectedSectionSettingId
    ).getElementsByTagName("section")[0].dataset.section_name;
    let css = await fetchFileFromURL(
      `${s3builderSource}/${section_id}/css/style.css`
    );
    css = css.replaceAll(
      "../assets",
      `${s3builderSource}/${section_id}/assets`
    );
    getSelectedSections().map((section, index) => {
      if (section.section_name == section_id) {
        section.section_css = css;
      }
    });
    setSelectedSections([...selectedSections]);
    // ReRenderHtml();
  };

  // image Editor
  const showItemInLeftDrawer = (menu) => {
    setDrawerWidth(
      menu === "Theme Setting" || menu === "Gallery"
        ? 350
        : menu === "Elements"
        ? 275
        : 315
    );
    setLeftDrawerType("expand");
    setSelectedlistItem(menu);
  };

  // left Drawer

  // const drawerWidth = 250;
  const drawerWidthRight = 400;
  const drawerWidthEditorMenu = 600;
  const drawerWidthRightSectionSetting = 400;
  const closedDrawerWidth = 40;
  const closedDrawerWidthHover = 195;
  const expandDrawerWidth = 350;
  const sectionDrawerWidth = 315;
  const elementsDrawerWidth = 275;
  const [selectedMenu, setSelectedMenu] = useState("");
  const [indexForAddSection, setIndexForAddSection] = useState(0);

  const showSectionItemInDrawer = (menu) => {
    setDrawerWidth(selectedMenu ? sectionDrawerWidth : closedDrawerWidthHover);
    setLeftDrawerType("expand");
    setSelectedlistItem(menu);
  };

  const collection = {
    validateElement,
    getParentNode,
    selectedElement,
    setSelectedElement,
    fetchFileFromURL,
    updateSelectedSectionTitle,
    getSelectedSectionDataset,
    getSelectedSectionSettingId,
    getAllSections,
    getSelectedSections,
    setSelectedSections,
    getSectionsListOfPageFromHTML,
    handleOnDragEnd,
    handleSectionOnSelect,
    selectedSections,
    setSelectedSectionSettingId,
    setSelectedSectionsIndex,
    selectedSectionsIndex,
    customerSubscriptionDetail,
    setCustomerSubscriptionDetail,
    _get_user_subscription,
    get_public_key,

    // Drawers
    setLeftMenu,
    leftMenu,
    leftMenuOpen,
    setLeftMenuOpen,
    rightMenuOpen,
    setRightMenuOpen,
    editorMenuOpen,
    setEditorMenuOpen,
    OpenRowSetting,
    setOpenRowSetting,
    SelectedRow,
    setSelectedRow,
    SelectedRowClasses,
    setSelectedRowClasses,
    setTargetSectionId,
    setOpenSliderSettings,
    openSliderSettings,
    targetSliderId,
    setTargetSliderId,
    // list block
    openListBlockDrawer,
    setOpenListBlockDrawer,

    // navlist
    selectedNavBarElement,
    setSelectedNavBarElement,
    // Mobile or Tablet Preview
    mobilePreview,
    setMobilePreview,
    tabletPreview,
    setTabletPreview,
    changeInHTML,
    setChangeInHTML,
    previewURL,
    // theme menu
    rightThemeMenuOpen,
    setRightThemeMenuOpen,
    // editable inputs
    isSaveLoading,
    setIsSaveLoading,
    handleChangeInEditableInputs,
    getEditAbleInputs,
    handleUpdateElement,
    handleupdateIMG,
    editAbleInputs,
    setEditAbleInputs,
    // element handlings
    handleRemoveElement,
    handleRemoveElementAndCheckCarousel,
    getSelectedElementTag,
    handleRemoveSelection,
    handleUpSelection,
    handleDownSelection,
    handleSaveTemplateToServer,
    CheckChangeExsistInHTML,
    Check_Class_Available_in_element,
    change_Class_in_element,
    getTextSelectedElement,
    // loading
    isSaveLoading,
    isPreviewLoading,
    isPublishLoading,
    // download zip
    handleDownload,
    // publish project
    handlePublishProject,
    // root values change function
    rootValues,
    handleChangeRootValue,
    getRootValues,
    setRootValuesFromProject,
    setRootValues,
    handleThemeClick,
    setDefaultRootValues,
    FetchPageAndUpdatePageRootValues,
    // plan setting
    planSettingsMenuOpen,
    setPlanSettingsMenuOpen,
    handleOpenPlanSetting,
    //  Event Setting
    handleOpenEventSetting,
    setEventSettingsMenuOpen,
    eventSettingsMenuOpen,
    manipulate,
    // Section Settings Functions
    handleGetAIAContentForSections,
    handleGetAIAContentForSectionsOnebyOne,
    EmptyAllSetStates,
    sectionSettingsMenuOpen,
    setSectionSettingsMenuOpen,
    FormSettingsMenuOpen,
    setFormSettingsMenuOpen,
    requiredFieldsList,
    isFieldNotAlwaysRequired,
    handleOpenSectionSettings,
    handleOpenFormSetting,
    handleDuplicateSection,
    handleUpdateSectionSettings,
    getSelectedSectionLists,
    openIframeSettingMenu,
    setOpenIframeSettingMenu,
    openIframeSettingEditor,
    setOpenIframeSettingEditor,
    selectedIFRAME,
    setSelectedIFRAME,
    checkElementIsIFRAME,
    openPlanCardSetting,
    setOpenPlanCardSetting,
    checkElementIsPaymentCard,
    handleSectionBackgroundVideoRun,
    // selected section styles
    selectedSectionElement,
    selectedSectionSettingId,
    getSelectedSectionStyle,
    getSelectedSectionheightwidth,
    updateSelectedSectionStyle,
    // selected element style
    getSelectedElementStyleByProperty,
    updateSelectedElementStyleByProperty,
    // classes
    getSelectedSectionClass,
    updateSelectedSectionClass,
    // element update class functions
    updateSelectedElementClass,
    getSelectedElementClassByType,
    // update Attribute
    updateAttributeOfSelectedElement,
    updateAttributeOfSelectedSection,
    // update menu list
    handleReplaceListElements,
    // Get i-frames
    getSelectedSectionFrames,
    //Get selected element
    getSelectedElement,
    // handleIMGHeightWidth,
    checkIsEditorElement,
    getSelectedElementAttributes,
    updateSelectedElementAttributes,
    handleUpdateStateFromDOM,
    //Meta
    metaStates,
    setMetaStates,
    // Duplicate Id function
    handleChangeDuplicateId,
    //selected element attribute states
    selectedElementAttributes,
    setSelectedElementAttributes,
    //Modal open/close states
    headerContentModal,
    setHeaderContentModal,
    aIModalOpen,
    setAIModalOpen,
    aIModalMinimize,
    setAIModalMinimize,
    aiWriterModal,
    setAiWriterModal,
    //Dialog open/close states
    isHeaderDialogOpen,
    setIsHeaderDialogOpen,
    //Edit mode
    getEditModeState,
    getIsDevState,
    setIsDev,
    //
    getSelectedSectionForm,
    getSelectedSectionFormByID,
    getSelectedSectionPlanByID,
    getSelectedSectionType,
    //margin & paddings
    updateSelectedElementSpacing,
    //parent A tag handling
    updateParentLink,
    updateParentLinkTarget,
    updateParentLinkToFunnel,
    getParentLink,
    // nav manipulations functions
    getTopMenuNavClasses,
    updateTopMenuNavClasses,
    getTopMenuNavStyle,
    updateTopMenuNavStyle,
    SelectedPage,
    setSelectedPage,
    setUseEditingProject,
    //
    editorRef,
    // rernder html after editing column
    ReRenderHtml,
    handleUpdateStateFromDOM,

    // do undo work
    SaveActionToStack,
    checkAndRemoveActionFromStack,
    checkHTMLExistorNotinStack,
    setElementsCopyToSessionStorage,
    checkRootValuesAndRemoveFromStack,
    UndoAction,
    RedoAction,
    HistoyStack,
    RedoStack,
    setHistoyStack,
    setRedoStack,
    CheckElementIsRow,
    checkElementIsForm,
    checkElementIsImage,
    RemoveRowBlock,

    handleUpateCss,

    selectedBoxDiv,
    setSelectedBoxDiv,
    openSelectedBoxDiv,
    setOpenSelectedBoxDiv,

    // Gallery
    updateUserAsserts,
    userAssertDetails,
    userImagesList,
    setUserImagesList,
    searchImageList,
    searchTemplateImageList,
    templateImagesList,
    pexelSavedImageList,
    setPexelSavedImageList,
    searchPexelSavedImageList,
    setSearchPexelSavedImageList,
    stockImagesList,
    searchStockImageList,
    setSearchImageList,
    userVideosList,
    setUserVideosList,
    searchVideosList,
    setSearchVideosList,
    RemoveUserGalleryClass,
    GetImagesList,
    GotoLoginPage,
    RedirectToClientPortal,

    //Add Section Dialog
    addSectionDialog,
    setAddSectionDialog,

    // left menu
    leftDrawerType,
    setLeftDrawerType,
    selectedlistItem,
    setSelectedlistItem,
    drawerWidth,
    setDrawerWidth,
    showItemInLeftDrawer,
    showSectionItemInDrawer,
    selectedMenu,
    setSelectedMenu,
    indexForAddSection,
    setIndexForAddSection,
    funnelGroup,
    setFunnelGroup,
    funnelDetails,
    setFunnelDetails,

    drawerWidthRight,
    drawerWidthEditorMenu,
    drawerWidthRightSectionSetting,
    closedDrawerWidth,
    closedDrawerWidthHover,
    expandDrawerWidth,
    sectionDrawerWidth,
    elementsDrawerWidth,

    // replaceable element
    openReplaceElementMenu,
    setOpenReplaceElementMenu,
    isReplaceableElement,
    setIsReplaceableElement,
    addElementOnTop,
    setAddElementOnTop,

    // imageEditor
    imageAnchorEl,
    setImageAnchorEl,
    // Icon editor
    setIconAnchorEl,
    isParentAnchorTag,
    iconAnchorEl,
    iconEditorPosition,
    //Button Editor
    buttonEditorAnchorEl,
    setButtonEditorAnchorEl,
    getIndex,
    //Text Editor
    textEditorAnchorEl,
    setTextEditorAnchorEl,
    //Right Menu
    setRightMenuAnchorEl,
    rightMenuAnchorEl,
    handleElementClick,
    isElementOfEditor,

    // Edit Button Popup
    setEditButtonPopupAnchorEl,
    editButtonPopupAnchorEl,
    editButtonPopupText,

    setIframeButtonPopupAnchorEl,
    iframeButtonPopupAnchorEl,
    handleAddIFRAMEListner,

    // Navlist Button
    setNavlistButtonPopupAnchorEl,
    navlistButtonPopupAnchorEl,
    handleAddNavListListner,

    // list button
    listButtonAnchorEl,
    setListButtonAnchorEl,
    handleAddListListner,

    // Box Setting Button
    setBoxSettingButtonAnchorEl,
    boxSettingButtonAnchorEl,
    BoxSettingClick,

    // Layout Setting Button
    setLayoutSettingButtonAnchorEl,
    layoutSettingButtonAnchorEl,
    handleOpenLayoutSetting,

    // Carousel Setting Button
    setSliderSettingButtonAnchorEl,
    sliderSettingButtonAnchorEl,
    handleOpenSliderSetting,

    // stripe button
    openStripeButtonModal,
    setOpenStripeButtonModal,
    activePaymentPlansList,
    setActivePaymentPlansList,
    getPlanList,
    currencyListOfVissionUser,

    // Book A Call Event
    setBookACallEventListing,
    bookACallEventListing,

    handleDragStart,

    //add_HTML_of_add_remove_button
    add_HTML_on_remove_block,
  };

  return (
    <BuilderContext.Provider value={collection}>
      {children}
    </BuilderContext.Provider>
  );
}
